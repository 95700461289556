import {AssertionUtils} from './assertion-utils';

export class ArrayUtils {
  public static arrayMove<T>(array: T[], from: number, to: number): void {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  }

  public static filterArrayOnAllProperties<T>(array: T[], filterText: string): T[] {
    return array.filter((object: T) => {
      let result = false;

      for (const [key, value] of Object.entries(object)) {
        if (!AssertionUtils.isNullOrUndefined(value) && key !== '_id' && value.toString().toLowerCase().includes(filterText.toLowerCase())) {
          result = true;
        }
      }
      return result;
    });
  }

  public static arrayReverseIndex<T>(array: T[], index: number): number {
    return array.length - index;
  }

  public static sum(array: number[]): number {
    if (!array || array.length === 0) {
      return 0;
    }
    return array.reduce((accumulator: number, currentValue: number) => accumulator + (currentValue || 0));
  }

  public static convertFalsyValuesToZeroAndGetMaxValue(array: number[]): number {
    if (!array || array.length === 0) {
      return undefined;
    }
    const validArray = array.map((x: number) => x || 0);
    return Math.max(...validArray);
  }

  public static distinctBy<TItem, TKey>(list: TItem[], selectorFn: (item: TItem) => TKey): TItem[] {
    if (AssertionUtils.isEmpty(list)) {
      return list;
    }
    return Array.from(new Set(list.map(selectorFn))).map((property: TKey) => list.find((item: TItem) => selectorFn(item) === property));
  }

  public static groupBy<TItem, TKey>(list: TItem[], selectorFn: (item: TItem) => TKey): Map<TKey, TItem[]> {
    const result = new Map<TKey, TItem[]>();
    for (const item of list) {
      const key = selectorFn(item);
      let group = result.get(key);
      if (group === undefined) {
        group = [];
        result.set(key, group);
      }
      group.push(item);
    }
    return result;
  }

  public static sortByKey<T>(array: T[], key: keyof T): T[] {
    ArrayUtils.sortBy(
      array,
      (item: T) => item[key],
      (a: any, b: any) => a.localeCompare(b)
    );
    return array;
  }

  public static sortBy<TParent, TChild>(array: TParent[], selector: (item: TParent) => TChild, compare?: (a: TChild, b: TChild) => number): void {
    compare ??= (a: any, b: any): number => a - b;
    array?.sort((a: TParent, b: TParent) => compare(selector(a), selector(b)));
  }

  public static toSortedBy<TParent, TChild>(array: TParent[], selector: (item: TParent) => TChild, compare?: (a: TChild, b: TChild) => number): TParent[] {
    const copy = [...array];
    ArrayUtils.sortBy(copy, selector, compare);
    return copy;
  }

  public static haveSameContent<T>(array1: T[], array2: T[], areEqual?: (item1: T, item2: T) => boolean): boolean {
    areEqual ??= (item1: T, item2: T): boolean => item1 === item2;
    return array1 === array2 || (array1.length === array2.length && array1.every((value: T, index: number) => areEqual(value, array2[index])));
  }

  public static readonly descending = (a: number, b: number): number => b - a;
  public static readonly ascending = (a: number, b: number): number => a - b;
}
