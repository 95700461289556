// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "6b8de14ca2-dirty",
    "hash": "6b8de14ca2",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "6b8de14ca2-dirty",
    "semverString": null,
    "version": "5.31.2"
};
