import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {getWeaveQualityClassFromQualityType} from '@application/helper/textile-data/machine-quality/get-weave-quality-class-from-quality-type';
import {PropertyValue} from '@domain/property-value';
import {CommercialMachineQuality} from '@domain/textile-data/machine-quality/commercial-machine-quality';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {ExtendedHttpParams} from '@infrastructure/http/extended-http-params';
import {GetAllMachineQualitiesParameters, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {CheckName, Conflict, Unit} from '@vdw/angular-component-library';
import {each, isNil, map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';

@Injectable()
export class HttpMachineQualitiesService implements MachineQualities {
  private readonly VARIABLE_NAMES_TO_HTTP_PARAMS_MAP = new Map<string, string>([
    ['showOnlyLatestVersion', 'only-latest-versions'],
    ['numberOfCreelPositions', 'creel-positions']
  ]);

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public update(item: WeaveQuality): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}machinequalities/${item.id}`, item.toJSON());
  }

  public updateOrCreateNewVersion(item: WeaveQuality): Observable<number> {
    return this.httpClient.put(`${this.backendConfiguration.getEndpoint()}machinequalities/${item.id}`, item.toJSON()).pipe(rxjsMap((response: {qualityId: number}) => response.qualityId));
  }

  public getAll(parameters?: Partial<GetAllMachineQualitiesParameters>): Observable<OverviewListMachineQuality[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities`, {params}).pipe(
      rxjsMap((machineQualitiesJSON: any) => {
        return map(machineQualitiesJSON, (machineQualityJSON: any) => {
          return OverviewListMachineQuality.fromJSON(machineQualityJSON);
        });
      })
    );
  }

  public getPathWidths(machineQualityId: number): Observable<PathWidth[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/path-widths`).pipe(
      rxjsMap((pathWidthsJSON: any) => {
        return map(pathWidthsJSON, (pathWidthJSON: any) => {
          return PathWidth.fromJSON(pathWidthJSON);
        });
      })
    );
  }

  public getFactorForConversionFromDentsToMillimeters(machineQualityId: number): Observable<number> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/factor-for-conversion-from-dents-to-millimeters`).pipe(
      rxjsMap((response: {factorForConversionFromDentsToMillimeters: number}) => {
        return response.factorForConversionFromDentsToMillimeters;
      })
    );
  }

  public getFactorForConversionFromMillimetersToDents(machineQualityId: number): Observable<number> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/factor-for-conversion-from-millimeters-to-dents`).pipe(
      rxjsMap((response: {factorForConversionFromMillimetersToDents: number}) => {
        return response.factorForConversionFromMillimetersToDents;
      })
    );
  }

  public getFactorForConversionFromMillimetersToPicks(machineQualityId: number): Observable<number> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/factor-for-conversion-from-millimeters-to-picks`).pipe(
      rxjsMap((response: {factorForConversionFromMillimetersToPicks: number}) => {
        return response.factorForConversionFromMillimetersToPicks;
      })
    );
  }

  public getFactorForConversionFromPicksToMillimeters(machineQualityId: number): Observable<number> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/${machineQualityId}/factor-for-conversion-from-picks-to-millimeters`).pipe(
      rxjsMap((response: {factorForConversionFromPicksToMillimeters: number}) => {
        return response.factorForConversionFromPicksToMillimeters;
      })
    );
  }

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/custom-settings`).pipe(
      rxjsMap((response: PropertyValue[]) => {
        return response;
      })
    );
  }

  public getSuggestionForWeftDensity(weaveStructureId: number, pickDensity: number): Observable<number> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/weftdensity-suggestion/weave-structure-id=${weaveStructureId}&pick-density=${pickDensity}`).pipe(
      rxjsMap((response: {suggestionForWeftDensity: number}) => {
        return response.suggestionForWeftDensity;
      })
    );
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/custom-settings/already-adjusted`).pipe(
      rxjsMap((response: {alreadyAdjustedCustomSettings: boolean}) => {
        return response.alreadyAdjustedCustomSettings;
      })
    );
  }

  public isIdentifierAvailable(name: string): Observable<CheckName> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}machinequalities/check-name`, {params}).pipe(rxjsMap((response: any) => CheckName.fromJSON(response)));
  }

  public save(item: WeaveQuality): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}machinequalities`, item.toJSON()).pipe(rxjsMap((response: {id: number}) => response.id));
  }

  public saveCustomSettings(chosenSettings: PropertyValue[]): Observable<void> {
    const settingsToSave: PropertyValue[] = [];

    each(chosenSettings, (chosenSetting: PropertyValue) => {
      let propertyValue: any = chosenSetting.propertyValue;

      if (!isNil(Unit.getKeyFromValue(chosenSetting.propertyValue))) {
        propertyValue = Unit.getKeyFromValue(chosenSetting.propertyValue);
      }

      settingsToSave.push({propertyName: chosenSetting.propertyName, propertyValue});
    });
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}machinequalities/custom-settings`, settingsToSave);
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}machinequalities/${id}`);
  }

  public getById(id: number): Observable<WeaveQuality> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/${id}`).pipe(
      rxjsMap((machineQualityJSON: any) => {
        return getWeaveQualityClassFromQualityType(WeaveQualityType[`${machineQualityJSON['type']}`]).fromJSON(machineQualityJSON);
      })
    );
  }

  public getListOfCommercialMachineQualities(): Observable<CommercialMachineQuality[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/commercial-machine-qualities`).pipe(
      rxjsMap((commercialMachineQualitiesJSON: any) => {
        return map(commercialMachineQualitiesJSON, (commercialMachineQualityJSON: any) => {
          return CommercialMachineQuality.fromJSON(commercialMachineQualityJSON);
        });
      })
    );
  }

  public getActiveMachineQualityForMachine(id: number): Observable<WeaveQuality> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/machine/${id}/active`).pipe(rxjsMap((machineQualityJSON: any) => WeaveQuality.fromJSON(machineQualityJSON)));
  }

  public getPossibleMachineQualitiesForMachine(id: number, parameters?: Partial<GetAllMachineQualitiesParameters>): Observable<WeaveQuality[]> {
    const params = new ExtendedHttpParams().setHttpParamsFromObject(parameters, this.VARIABLE_NAMES_TO_HTTP_PARAMS_MAP);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/machine/${id}/possible`, {params}).pipe(
      rxjsMap((machineQualitiesJSON: any) => {
        return map(machineQualitiesJSON, (machineQualityJSON: any) => {
          return WeaveQuality.fromJSON(machineQualityJSON);
        });
      })
    );
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient.get(`${this.backendConfiguration.getAggregatorEndpoint()}machinequalities/${id}/conflicts`).pipe(
      rxjsMap((conflictsJSON: any) => {
        return map(conflictsJSON, (conflictJSON: any) => {
          return Conflict.fromJSON(conflictJSON);
        });
      })
    );
  }

  public getListOfConditionPatterns(): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/jute/condition-patterns`).pipe(
      rxjsMap((conditionPatterns: string[]) => {
        return conditionPatterns;
      })
    );
  }

  public getListOfPatternFields(): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}machinequalities/jute/pattern-fields`).pipe(
      rxjsMap((conditionPatterns: string[]) => {
        return conditionPatterns;
      })
    );
  }

  public getNameGenerationProperties(): Observable<string[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}machinequalities/custom-settings/name-generation-properties`)
      .pipe(rxjsMap((nameGenerationProperties: string[]) => nameGenerationProperties));
  }

  public generateName(item: WeaveQuality): Observable<string> {
    return this.httpClient.post<string>(`${this.backendConfiguration.getEndpoint()}machinequalities/custom-settings/generate-name`, item.toJSON());
  }
}
