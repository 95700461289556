<section fxFlex fxLayout="column">
  <vdw-header [title]="'NEW_UPDATE_DIALOG.DIALOG_BAR_TITLE' | bmsTranslateAsync: {version: changelogs[0]?.version} | async" svgIcon="update-blue" [canShowClose]="true"></vdw-header>

  <article class="dialog-content" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" fxFlex="1 1 0%">
    <h3 class="font-weight-semibold">{{ 'NEW_UPDATE_DIALOG.TITLE' | bmsTranslateAsync: {version: changelogs[0]?.version} | async }}</h3>
    <p class="b2 description">{{ 'NEW_UPDATE_DIALOG.DESCRIPTION' | bmsTranslateAsync | async }}</p>
    <mat-icon svgIcon="update-available-center-image" class="main-image" fxFlex="noshrink"></mat-icon>
    <div *ngFor="let changelog of changelogs" fxLayout="column">
      <h4>{{ 'NEW_UPDATE_DIALOG.NEW_FEATURES_LIST_TITLE' | bmsTranslateAsync: {version: changelog.version} | async }}</h4>

      <div fxFlex class="features-list" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
        <div class="changes-type" *ngFor="let type of getChanges(changelog.version)" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
          <p class="b2 font-weight-semibold">{{ 'CLIENT_VERSION.CHANGES_TYPE.' + (type | uppercase) | bmsTranslateAsync | async }}</p>
          <ul>
            <li *ngFor="let change of getChangesOfType(changelog.version, type)">
              <p class="b2">{{ change }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </article>

  <vdw-footer chooseEventName="GENERAL.ACTIONS.UPDATE" [centered]="true" [withCancelButton]="false" (chooseEvent)="updateApp()"></vdw-footer>
</section>
