import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApplicationLayoutService, AssertionUtils, BaseComponent, LocalStorageService, ResizeElementDirective} from '@vdw/angular-component-library';
import {AppComponent} from '../../../app.component';
import {takeUntil} from 'rxjs';

@Component({
  selector: 'app-navigation-resizer',
  templateUrl: './navigation-resizer.component.html',
  styleUrls: ['./navigation-resizer.component.scss']
})
export class NavigationResizerComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('resizer') public resizer: ElementRef<HTMLElement>;
  @ViewChild(ResizeElementDirective) public resizeDirective: ResizeElementDirective;

  public readonly SIDEBAR_MIN_WIDTH = 240;
  public readonly SIDEBAR_MAX_WIDTH = 600;

  private cachedResizeValue: number;
  private cachedSidebarWidth: number;
  private drawerElement: HTMLElement;
  private drawerContent: HTMLElement;

  public constructor(
    private readonly appComponent: AppComponent,
    private readonly localStorage: LocalStorageService,
    public readonly applicationLayoutService: ApplicationLayoutService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.appComponent.drawerInitialized.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => this.init());
  }

  public ngAfterViewInit(): void {
    this.appComponent.resizeDirective = this.resizeDirective;
  }

  public init(): void {
    if (!AssertionUtils.isNullOrUndefined(this.applicationLayoutService.matDrawer)) {
      this.drawerElement = this.applicationLayoutService.matDrawer._content.nativeElement.parentElement;
      this.drawerContent = this.applicationLayoutService.matDrawer._container?._content?.getElementRef()?.nativeElement;

      this.cachedSidebarWidth = this.localStorage.get<number>('sidebar-width') ?? this.SIDEBAR_MIN_WIDTH;

      if (!AssertionUtils.isNullOrUndefined(this.drawerElement)) {
        this.drawerElement.style.width = `${this.cachedSidebarWidth}px`;
      }

      if (!AssertionUtils.isNullOrUndefined(this.resizer)) {
        this.resizer.nativeElement.style.left = `${this.cachedSidebarWidth - this.resizer.nativeElement.clientWidth / 2}px`;
      }

      if (!this.isSideNavCollapsed() && !AssertionUtils.isNullOrUndefined(this.drawerContent)) {
        this.drawerContent.style.marginLeft = `${this.cachedSidebarWidth}px`;
      }
    }
  }

  public onResize(event: MouseEvent): void {
    this.cachedResizeValue = event.x;
    this.drawerElement.style.width = `${event.x}px`;
    this.drawerContent.style.marginLeft = `${event.x}px`;
    this.resizer.nativeElement.style.left = `${event.x - this.resizer.nativeElement.clientWidth / 2}px`;

    event.stopPropagation();
  }

  public onResizeEnd(): void {
    if (this.cachedSidebarWidth !== this.cachedResizeValue && !AssertionUtils.isNullOrUndefined(this.cachedResizeValue)) {
      this.cachedSidebarWidth = this.cachedResizeValue;
      this.localStorage.set('sidebar-width', this.cachedResizeValue);
    }
  }

  public resizeEntered(): void {
    this.applicationLayoutService.resizeHovered = true;
  }

  public resizeLeft(): void {
    this.applicationLayoutService.resizeHovered = false;
  }

  public isSideNavCollapsed(): boolean {
    return this.appComponent.isSideNavCollapsed();
  }
}
