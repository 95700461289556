import {Component, ElementRef, ViewChild} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {isNil, isUndefined} from 'lodash-es';
import {GridInputCellRendererParams} from './grid-input-cell-renderer-params';

@Component({
  styleUrls: ['./grid-input.component.scss'],
  templateUrl: './grid-input.component.html'
})
export class GridInputComponent implements ICellRendererAngularComp {
  @ViewChild('inputElement') public inputElement: ElementRef;
  public updateValue: (rowData, value: number | string) => void;
  public minValue: number;
  public isConfigurableValue = false;
  private value: number | string;
  private canShowInput: (rowData) => boolean;
  private canShowRequiredError: (rowData, value: number | string) => boolean;
  private rowData: any;

  public agInit(params: GridInputCellRendererParams): void {
    this.canShowInput = params.canShowInput;
    this.canShowRequiredError = params.canShowRequiredError;
    this.value = params.value;
    this.updateValue = params.updateValue;
    this.minValue = params.minValue || null;
    this.rowData = params.node.data;
    this.isConfigurableValue = params.node.data.configurableValue;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public canShowInputElement(): boolean {
    return !isNil(this.canShowInput) && this.canShowInput(this.rowData);
  }

  public canShowRequiredErrorElement(): boolean {
    return !isNil(this.canShowRequiredError) && this.canShowRequiredError(this.rowData, this.value);
  }

  public canShowStringInput(): boolean {
    return this.isConfigurableValue && (this.rowData.name === 'alphabetic start' || this.rowData.name === 'alphanumeric start');
  }

  public getLabel(): string {
    return this.isConfigurableValue ? 'ANGULAR_COMPONENT_LIBRARY.GRID_INPUT.VALUE' : 'ANGULAR_COMPONENT_LIBRARY.GRID_INPUT.MAXIMUM_LENGTH';
  }

  public getValue(): string {
    return this.canShowStringInput && (isUndefined(this.value) || isNil(this.value)) ? '' : `${this.value}`.toUpperCase();
  }

  public setValue(value: string): void {
    if (value) {
      let valueToSet = this.canShowStringInput() ? value.toUpperCase() : Number(value);
      if (this.minValue && valueToSet < this.minValue && !this.canShowStringInput()) {
        valueToSet = this.minValue;
      }
      this.updateValue(this.rowData, valueToSet);
      this.inputElement.nativeElement.value = valueToSet;
      this.value = valueToSet;
    } else {
      this.updateValue(this.rowData, null);
      this.inputElement.nativeElement.value = value;
      this.value = null;
    }
  }
}
