import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {RuntimeApplicationEnvironment, RUNTIME_APPLICATION_ENVIRONMENT} from '@application/configuration/runtime-application-environment';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Company} from '@domain/profile/company';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {HoverDialogBuilderService, MenuTreeViewContextService, SignalrHubConnectionFactoryService, WINDOW} from '@vdw/angular-component-library';
import {AccountSwitcherDialogData} from './account-switcher-dialog-data.interface';
import {NavigationService} from '@application/services/navigation/navigation.service';

@Component({
  selector: 'app-account-switcher-dialog',
  templateUrl: './account-switcher-dialog.component.html',
  styleUrls: ['./account-switcher-dialog.component.scss']
})
export class AccountSwitcherDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public companySubscriptions: Map<string, Subscription[]> = new Map<string, Subscription[]>();
  private activeSubscription: Subscription;

  public constructor(
    private readonly router: Router,
    @Inject(WINDOW) private readonly window: Window,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly backendConfiguration: BackendConfiguration,
    private readonly navigationService: NavigationService,
    private readonly hoverDialogService: HoverDialogBuilderService,
    private readonly menuTreeViewContext: MenuTreeViewContextService,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(MAT_DIALOG_DATA) public readonly data: AccountSwitcherDialogData,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService,
    @Inject(RUNTIME_APPLICATION_ENVIRONMENT) private readonly runtimeEnvironment: RuntimeApplicationEnvironment
  ) {}

  public ngOnInit(): void {
    this.activeSubscription = this.authentication.getCurrentSubscription();

    this.data.companies.forEach((company: Company) => {
      const subscriptions = this.data.subscriptions.filter((sub: Subscription) => sub.company.id === company.id);
      this.companySubscriptions.set(company.id, subscriptions);
    });
  }

  public ngAfterViewInit(): void {
    this.hoverDialogService.registerElement(this.viewContainerRef.element.nativeElement, false);
  }

  public ngOnDestroy(): void {
    this.hoverDialogService.setHovered(this.viewContainerRef.element.nativeElement, false);
  }

  public isActiveSubscription(subscriptionId: string): boolean {
    return this.activeSubscription.id === subscriptionId;
  }

  public subscriptionClicked(subscriptionId: string, companyId: string): void {
    const subscription = this.data.subscriptions.find((sub: Subscription) => sub.id === subscriptionId);
    this.signalrHubConnectionFactoryService.disconnectAll();

    this.backendConfiguration.setCompanyId(companyId);
    this.backendConfiguration.setSubscriptionId(subscriptionId);
    this.authentication.setCurrentSubscription(subscription);

    this.signalrHubConnectionFactoryService.connectAll();
    const route = this.navigationService.getDefaultRoute(subscription);

    route === this.router.url ? this.window.location.reload() : this.router.navigateByUrl(route);
  }

  public profileClicked(): void {
    this.menuTreeViewContext.setActive(null);
    this.router.navigateByUrl(RouteUtils.paths.profile.absolutePath);
  }

  public logoutClicked(): void {
    this.authentication.logout();
  }

  public manageSubscriptionsClicked(): void {
    this.window.open(this.runtimeEnvironment.getEnvironment().creditsUrl);
  }
}
