import {ColorRoute} from '@application/helper/routing/color-route';
import {ColorSetRoute} from '@application/helper/routing/color-set-route';
import {ColoredYarnSetRoute} from '@application/helper/routing/colored-yarn-set-route';
import {CreelRoute} from '@application/helper/routing/creel-route';
import {FinishingTemplateRoute} from '@application/helper/routing/finishing-template-route';
import {MachineQualityRoute} from '@application/helper/routing/machine-quality-route';
import {RouteBranch} from '@application/helper/routing/route-branch';
import {WeaveStructureRoute} from '@application/helper/routing/weave-structure-route';
import {YarnRoute} from '@application/helper/routing/yarn-route';
import {YarnSetRoute} from '@application/helper/routing/yarn-set-route';
import {ColoredYarnRoute} from './colored-yarn-route';
import {CompanyWeaveStructureRoute} from './company-weave-structure-route';
import {GroupedWeaveStructureRoute} from './grouped-weave-structure-route';
import {TuftProductRoute} from './tuft-product-route';
import {WeaveProductRoute} from './weave-product-route';
import {WeftColoredYarnSetRoute} from './weft-colored-yarn-set-route';
import {PlasticProductRoute} from './plastic-product-route';

export class TexStyleRoute extends RouteBranch {
  private _finishingTemplate: FinishingTemplateRoute;
  private _color: ColorRoute;
  private _colorSet: ColorSetRoute;
  private _coloredYarn: ColoredYarnRoute;
  private _yarnSet: YarnSetRoute;
  private _coloredYarnSet: ColoredYarnSetRoute;
  private _weftColoredYarnSet: WeftColoredYarnSetRoute;
  private _yarn: YarnRoute;
  private _machineQuality: MachineQualityRoute;
  private _creel: CreelRoute;
  private _weaveProduct: WeaveProductRoute;
  private _weaveStructure: WeaveStructureRoute;
  private _groupedWeaveStructure: GroupedWeaveStructureRoute;
  private _companyWeaveStructure: CompanyWeaveStructureRoute;
  private _tuftProduct: TuftProductRoute;
  private _plasticProduct: PlasticProductRoute;

  public get finishingTemplate(): FinishingTemplateRoute {
    return this._finishingTemplate;
  }

  public set finishingTemplate(value: FinishingTemplateRoute) {
    this.replace(this._finishingTemplate, value);
    this._finishingTemplate = value;
  }

  public get color(): ColorRoute {
    return this._color;
  }

  public set color(value: ColorRoute) {
    this.replace(this._color, value);
    this._color = value;
  }

  public get colorSet(): ColorSetRoute {
    return this._colorSet;
  }

  public set colorSet(value: ColorSetRoute) {
    this.replace(this._colorSet, value);
    this._colorSet = value;
  }

  public get yarnSet(): YarnSetRoute {
    return this._yarnSet;
  }

  public set yarnSet(value: YarnSetRoute) {
    this.replace(this._yarnSet, value);
    this._yarnSet = value;
  }

  public get coloredYarnSet(): ColoredYarnSetRoute {
    return this._coloredYarnSet;
  }

  public set coloredYarnSet(value: ColoredYarnSetRoute) {
    this.replace(this._coloredYarnSet, value);
    this._coloredYarnSet = value;
  }

  public get weftColoredYarnSet(): WeftColoredYarnSetRoute {
    return this._weftColoredYarnSet;
  }

  public set weftColoredYarnSet(value: WeftColoredYarnSetRoute) {
    this.replace(this._weftColoredYarnSet, value);
    this._weftColoredYarnSet = value;
  }

  public get coloredYarn(): ColoredYarnRoute {
    return this._coloredYarn;
  }

  public set coloredYarn(value: ColoredYarnRoute) {
    this.replace(this._coloredYarn, value);
    this._coloredYarn = value;
  }

  public get yarn(): YarnRoute {
    return this._yarn;
  }

  public set yarn(value: YarnRoute) {
    this.replace(this._yarn, value);
    this._yarn = value;
  }

  public get machineQuality(): MachineQualityRoute {
    return this._machineQuality;
  }

  public set machineQuality(value: MachineQualityRoute) {
    this.replace(this._machineQuality, value);
    this._machineQuality = value;
  }

  public get creel(): CreelRoute {
    return this._creel;
  }

  public set creel(value: CreelRoute) {
    this.replace(this._creel, value);
    this._creel = value;
  }

  public get weaveProduct(): WeaveProductRoute {
    return this._weaveProduct;
  }

  public set weaveProduct(value: WeaveProductRoute) {
    this.replace(this._weaveProduct, value);
    this._weaveProduct = value;
  }

  public get weaveStructure(): WeaveStructureRoute {
    return this._weaveStructure;
  }

  public set weaveStructure(value: WeaveStructureRoute) {
    this.replace(this._weaveStructure, value);
    this._weaveStructure = value;
  }

  public get groupedWeaveStructure(): GroupedWeaveStructureRoute {
    return this._groupedWeaveStructure;
  }

  public set groupedWeaveStructure(value: GroupedWeaveStructureRoute) {
    this.replace(this._groupedWeaveStructure, value);
    this._groupedWeaveStructure = value;
  }

  public get companyWeaveStructure(): CompanyWeaveStructureRoute {
    return this._companyWeaveStructure;
  }

  public set companyWeaveStructure(value: CompanyWeaveStructureRoute) {
    this.replace(this._companyWeaveStructure, value);
    this._companyWeaveStructure = value;
  }

  public get tuftProduct(): TuftProductRoute {
    return this._tuftProduct;
  }

  public set tuftProduct(value: TuftProductRoute) {
    this.replace(this._tuftProduct, value);
    this._tuftProduct = value;
  }

  public get plasticProduct(): PlasticProductRoute {
    return this._plasticProduct;
  }

  public set plasticProduct(value: PlasticProductRoute) {
    this.replace(this._plasticProduct, value);
    this._plasticProduct = value;
  }
}
