import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {InputL10nDecimalModule} from '../../custom-directives/input-l10n-decimal/input-l10n-decimal.module';
import {TranslationModule} from '../../translation/translation.module';
import {ZoneInputComponent} from './zone-input.component';

@NgModule({
  declarations: [ZoneInputComponent],
  imports: [CommonModule, FormsModule, MatIconModule, TranslationModule, InputL10nDecimalModule],
  exports: [ZoneInputComponent]
})
export class ZoneInputModule {}
