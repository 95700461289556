import {ApplicationRef, ChangeDetectorRef, ComponentRef, createComponent} from '@angular/core';
import {DialogPosition, MatDialogRef} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {concat, concatAll, distinctUntilChanged, filter, first, map, merge, Observable, Subject, takeUntil} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {DOMUtils} from '../../common/utils/dom-utils';
import {ArrowPosition} from './arrow-position.enum';
import {RepositionWatchDialog} from './reposition-watch-dialog.interface';

export class RepositionWatchDialogService implements RepositionWatchDialog {
  private dialogRef: MatDialogRef<any>;
  private changeDetectorRef: ChangeDetectorRef;
  private arrowComponentRef: ComponentRef<MatIcon>;
  private showArrow: boolean;
  private actualMargin: number;

  private readonly MARGIN_IN_PX = 4;
  private readonly ARROW_WIDTH_IN_PX = 24;
  private readonly ARROW_OFFSET_IN_PX = 5;
  private readonly unSubscribeOnDestroy = new Subject<any>();
  private readonly DIALOG_SURFACE_CLASS = 'mat-mdc-dialog-surface';

  public constructor(
    private readonly window: Window,
    private readonly document: Document,
    private readonly applicationRef: ApplicationRef
  ) {}

  public initWithPosition(
    dialogRef: MatDialogRef<any>,
    changeDetectorRef: ChangeDetectorRef,
    arrowPosition: ArrowPosition,
    x: number,
    y: number,
    showArrow: boolean,
    autoHeight: boolean,
    autoWidth: boolean,
    margin: number
  ): void {
    this.init(dialogRef, changeDetectorRef, showArrow);

    this.actualMargin = margin ?? this.MARGIN_IN_PX;

    this.getEventsToUpdatePosition()
      .pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe(() => {
        const [position, [width, height]] = this.getPositionAndSize(arrowPosition, x, y);
        this.dialogRef.updatePosition(position);

        if (autoHeight && !autoWidth) {
          this.dialogRef.updateSize(`${width}px`);
        } else if (!autoHeight && autoWidth) {
          this.dialogRef.updateSize(null, `${height}px`);
        } else {
          this.dialogRef.updateSize(`${width}px`, `${height}px`);
        }
      });

    if (showArrow) {
      this.addArrow(arrowPosition, x, y, 0);
    }
  }

  public initWithElement(
    dialogRef: MatDialogRef<any>,
    changeDetectorRef: ChangeDetectorRef,
    arrowPosition: ArrowPosition,
    element: Element,
    showArrow: boolean,
    alignRight: boolean,
    verticalOffset: number,
    horizontalOffset: number,
    autoHeight: boolean,
    autoWidth: boolean,
    margin: number
  ): void {
    this.init(dialogRef, changeDetectorRef, showArrow);

    this.actualMargin = margin ?? this.MARGIN_IN_PX;
    const eventsToUpdatePosition = merge(DOMUtils.observeResize(element), this.getEventsToUpdatePosition());

    eventsToUpdatePosition.pipe(takeUntil(this.unSubscribeOnDestroy)).subscribe(() => {
      if (showArrow) {
        const [x, y] = this.getArrowPosition(arrowPosition, element);

        this.setArrowStyling(arrowPosition, x, y, verticalOffset);
      }

      const [position, [width, height]] = this.getPositionAndSizeWithElement(arrowPosition, element, alignRight, verticalOffset, horizontalOffset);

      this.dialogRef.updatePosition(position);

      if (autoHeight && !autoWidth) {
        this.dialogRef.updateSize(`${width}px`);
      } else if (!autoHeight && autoWidth) {
        this.dialogRef.updateSize(null, `${height}px`);
      } else {
        this.dialogRef.updateSize(`${width}px`, `${height}px`);
      }
    });
    if (showArrow) {
      const [x, y] = this.getArrowPosition(arrowPosition, element);

      this.addArrow(arrowPosition, x, y, verticalOffset);
    }
  }

  public destroy(): void {
    this.unSubscribeOnDestroy.next(null);
    this.unSubscribeOnDestroy.complete();

    if (this.showArrow) {
      this.applicationRef.detachView(this.arrowComponentRef.hostView);

      this.document.body.removeChild(this.arrowComponentRef.location.nativeElement);
    }
  }

  private init(dialogRef: MatDialogRef<any>, changeDetectorRef: ChangeDetectorRef, showArrow: boolean = true): void {
    this.dialogRef = dialogRef;
    this.changeDetectorRef = changeDetectorRef;
    this.showArrow = showArrow;
  }

  private getEventsToUpdatePosition(): Observable<void> {
    return concat(
      DOMUtils.observeMutations(this.document.body, {childList: true, subtree: true}).pipe(
        filter(() => !AssertionUtils.isNullOrUndefined(this.getComponent())),
        concatAll(),
        first()
      ),
      DOMUtils.observeWindowEvents(this.window, 'resize').pipe(distinctUntilChanged())
    ).pipe(map(() => null));
  }

  private getArrowPosition(arrowPosition: ArrowPosition, element: Element, alignRight: boolean = false): [number, number] {
    const rect = element.getBoundingClientRect();

    switch (arrowPosition) {
      case ArrowPosition.LEFT:
        return [rect.right, rect.top + rect.height / 2];
      case ArrowPosition.RIGHT:
        return [rect.left, rect.top + rect.height / 2];
      case ArrowPosition.TOP:
        return [alignRight ? rect.right : rect.left + rect.width / 2, rect.bottom];
      case ArrowPosition.BOTTOM:
        return [alignRight ? rect.right : rect.left + rect.width / 2, rect.top];
    }
  }

  private getPositionAndSizeWithElement(arrowPosition: ArrowPosition, element: Element, alignRight: boolean, verticalOffset: number, horizontalOffset: number): [DialogPosition, [number, number]] {
    let [x, y] = this.getArrowPosition(arrowPosition, element, alignRight);
    return this.getPositionAndSize(arrowPosition, x, y, alignRight, verticalOffset, horizontalOffset);
  }

  private getPositionAndSize(
    arrowPosition: ArrowPosition,
    x: number,
    y: number,
    alignRight: boolean = false,
    verticalOffset: number = 0,
    horizontalOffset: number = 0
  ): [DialogPosition, [number, number]] {
    const component = this.getComponent();
    component.style.overflow = 'hidden';

    const rect = this.document.getElementById(this.dialogRef.id)?.getBoundingClientRect();

    let dialogPosition: DialogPosition;
    let top, left: number;
    const arrowWidthInPx = this.showArrow ? this.ARROW_WIDTH_IN_PX : 0;
    const arrowOffsetInPx = this.showArrow ? this.ARROW_OFFSET_IN_PX : 0;

    switch (arrowPosition) {
      case ArrowPosition.LEFT:
        top = y - Math.ceil(rect.height / 2) - arrowWidthInPx / 2 + verticalOffset;
        dialogPosition = {left: `${Math.floor(x + arrowWidthInPx - arrowOffsetInPx) + horizontalOffset}px`, top: `${this.fitTop(top, component.scrollHeight)}px`};
        break;
      case ArrowPosition.RIGHT:
        top = y - Math.ceil(rect.height / 2) - arrowWidthInPx / 2 + verticalOffset;
        dialogPosition = {
          right: `${Math.ceil(this.window.innerWidth - x + arrowWidthInPx - arrowOffsetInPx) + horizontalOffset}px`,
          top: `${this.fitTop(top, component.scrollHeight)}px`
        };
        break;
      case ArrowPosition.TOP:
        left = (alignRight ? x - rect.width : x - Math.ceil(rect.width / 2)) + horizontalOffset;
        dialogPosition = {left: `${this.fitLeft(left, rect.width)}px`, top: `${Math.ceil(y + arrowWidthInPx - arrowOffsetInPx) + verticalOffset}px`};
        break;
      case ArrowPosition.BOTTOM:
        left = (alignRight ? x - rect.width : x - Math.ceil(rect.width / 2)) + horizontalOffset;
        dialogPosition = {left: `${this.fitLeft(left, rect.width)}px`, bottom: `${Math.ceil(this.window.innerHeight - y + arrowWidthInPx - arrowOffsetInPx) + verticalOffset}px`};
        break;
    }

    return [dialogPosition, [Math.min(rect.width, Math.ceil(this.window.innerWidth) - this.actualMargin * 2), Math.min(rect.height, Math.ceil(this.window.innerHeight) - this.actualMargin * 2)]];
  }

  private fitTop(top: number, height: number): number {
    height = Math.ceil(height);
    if (height > this.window.innerHeight) {
      return this.actualMargin;
    }

    if (top + height + this.actualMargin > this.window.innerHeight) {
      top = Math.ceil(this.window.innerHeight - height - this.actualMargin);
    }

    return Math.max(top, this.actualMargin);
  }

  private fitLeft(left: number, width: number): number {
    left = Math.ceil(left);
    if (width > this.window.innerWidth) {
      return this.actualMargin;
    }

    if (left + width + this.actualMargin > this.window.innerWidth) {
      left = Math.ceil(this.window.innerWidth - width - this.actualMargin);
    }

    return Math.max(left, this.actualMargin);
  }

  private addArrow(arrowPosition: ArrowPosition, x: number, y: number, verticalOffset: number): void {
    this.arrowComponentRef = createComponent(MatIcon, {
      environmentInjector: this.applicationRef.injector
    });

    this.arrowComponentRef.instance.svgIcon = 'atoms-arrow';

    this.setArrowStyling(arrowPosition, x, y, verticalOffset);

    this.applicationRef.attachView(this.arrowComponentRef.hostView);

    this.document.body.appendChild(this.arrowComponentRef.location.nativeElement);

    this.changeDetectorRef?.detectChanges();
  }

  private setArrowStyling(arrowPosition: ArrowPosition, x: number, y: number, verticalOffset: number): void {
    const nativeElement = this.arrowComponentRef.location.nativeElement as HTMLElement;

    const className = 'arrow';
    if (nativeElement.classList.contains(className)) {
      nativeElement.classList.add(className);
    }

    nativeElement.style.position = 'absolute';
    nativeElement.style['z-index'] = 1001;

    switch (arrowPosition) {
      case ArrowPosition.LEFT: {
        nativeElement.style.left = `${x}px`;
        nativeElement.style.top = `${y - this.ARROW_WIDTH_IN_PX / 2}px`;
        break;
      }
      case ArrowPosition.RIGHT: {
        nativeElement.style.left = `${x - this.ARROW_WIDTH_IN_PX}px`;
        nativeElement.style.top = `${y - this.ARROW_WIDTH_IN_PX / 2}px`;
        nativeElement.style.transform = 'rotate(180deg)';
        break;
      }
      case ArrowPosition.TOP: {
        nativeElement.style.left = `${x - this.ARROW_WIDTH_IN_PX / 2}px`;
        nativeElement.style.top = `${verticalOffset + y}px`;
        nativeElement.style.transform = 'rotate(90deg)';
        break;
      }
      case ArrowPosition.BOTTOM: {
        nativeElement.style.left = `${x - this.ARROW_WIDTH_IN_PX / 2}px`;
        nativeElement.style.top = `${verticalOffset + y - this.ARROW_WIDTH_IN_PX}px`;
        nativeElement.style.transform = 'rotate(270deg)';
        break;
      }
    }
  }

  private getComponent(): HTMLElement {
    const dialogContainer = this.document.getElementById(this.dialogRef.id);
    return dialogContainer?.getElementsByClassName(this.DIALOG_SURFACE_CLASS)?.item(0).firstChild as HTMLElement;
  }
}
