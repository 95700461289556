import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {LabelModule} from '../ellipsis-label/label.module';
import {MenuItemComponent} from './menu-item/menu-item.component';
import {MenuTreeViewContextService} from './menu-tree-view-context.service';
import {MenuTreeViewComponent} from './menu-tree-view.component';

@NgModule({
  declarations: [MenuItemComponent, MenuTreeViewComponent],
  providers: [MenuTreeViewContextService],
  imports: [CommonModule, MatIconModule, MatButtonModule, LabelModule],
  exports: [MenuItemComponent, MenuTreeViewComponent]
})
export class MenuTreeViewModule {}
