<div [class.padding-container]="config.isObjectSelection" class="full-width full-height bms-theme flex-column">
  <div *ngIf="config.headerTitle" class="header">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border back-button" (click)="cancel()" [matTooltip]="'back'" matTooltipPosition="above" matTooltipClass="bms-theme">
      <mat-icon class="back-icon" svgIcon="solid-arrow-back"></mat-icon>
    </button>
    <div class="font-weight-semibold header-title">{{ config.headerTitle }}</div>
  </div>

  <div class="actions">
    <button *ngIf="hasNewClickedEvent()" class="action-button" mat-flat-button (click)="config.withNewClicked($event)">{{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.NEW' | bmsTranslate }}</button>
  </div>

  <div #gridContainer class="full-width full-height">
    <ag-grid-angular #grid class="full-width full-height ag-theme-vdw" [rowData]="config.rowData" [gridOptions]="config.gridOptions"></ag-grid-angular>
  </div>

  <div *ngIf="config.isObjectSelection" class="footer">
    <div class="footer-start" *ngIf="config.gridOptions?.rowSelection === 'multiple' && (selectedItemCount !== 0 || onlyShowSelected)">
      <vdw-toggle-button class="toggle" [label]="showSelected" [checked]="onlyShowSelected" (checkedChange)="toggleShowOnlySelected($event)"></vdw-toggle-button>
      <div class="selected-item-count font-weight-semibold">{{ selectedItemCount }} {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.OF' | bmsTranslate }} {{ config.rowData?.length }}</div>
    </div>

    <div class="footer-end">
      <button mat-button (click)="cancel()">{{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.CANCEL' | bmsTranslate }}</button>
      <button class="action-button" mat-flat-button (click)="select()" [disabled]="selectedItemCount === 0">
        {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.CONFIRM_SELECTION' | bmsTranslate }}
      </button>
    </div>
  </div>
</div>
