import {Component, Inject, Input, OnInit} from '@angular/core';
import {PriorityLevel} from '@domain/event/priority-level.enum';
import {Notification} from '@domain/notifications/notification';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {OnNotificationMarkedAsReadInterface} from '@infrastructure/signalr/notifications/on-notification-marked-as-read.interface';
import {ArrayUtils, AssertionUtils, BaseComponent, EnumUtils, SelectionListItem} from '@vdw/angular-component-library';
import {debounceTime, distinctUntilChanged, filter, Observable, of, takeUntil} from 'rxjs';
import {MachineOverviewViewMode} from '../machine-overview/machine-overview-view-mode.enum';
import {OverviewMachine} from '../machine-overview/overview-machine';
import {OverviewMachineGroup} from '../machine-overview/overview-machine-group';
import {NotificationListSettingsComponent} from './notification-list/notification-list-settings.component';
import {NotificationsService} from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends BaseComponent implements OnInit {
  @Input() public overviewGroupId: number;
  @Input() public floorplanMachines: OverviewMachine[];
  @Input() public group: Observable<OverviewMachineGroup>;
  @Input() public initialCurrentGroup: OverviewMachineGroup;
  @Input() public machineOverviewMode?: MachineOverviewViewMode;

  public unreadCount: Observable<number>;
  public readonly notificationSettingsComponent = NotificationListSettingsComponent;
  public notificationsList: Notification[] = [];
  public currentGroup: OverviewMachineGroup;
  public VIEW_NOTIFICATIONS_PERMISSION = Permission.ALERT_VIEW_NOTIFICATIONS;
  public scaleImage = 1;
  protected priorities: SelectionListItem[] = [];
  private enabledPriorities: PriorityLevel[] = [];
  private loading = false;
  private currentSubscription: Subscription;

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly notificationsService: NotificationsService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setScale(272, 178);
    this.currentSubscription = this.authentication.getCurrentSubscription();
    if (this.currentSubscription.hasPermission(this.VIEW_NOTIFICATIONS_PERMISSION)) {
      this.notificationsList = [];
      this.unreadCount = this.notificationsService.unreadCount;
      EnumUtils.getEnumNames(PriorityLevel).forEach((key: string) => this.priorities.push({name: `BMSCONFIG.EVENTS.PRIORITY_LEVEL.${key}`, value: true} as SelectionListItem));

      this.setEnabledPriorities();
      this.subscribeToGroupChanges();

      if (!AssertionUtils.isNullOrUndefined(this.initialCurrentGroup)) {
        this.currentGroup = this.initialCurrentGroup;
        this.getNotifications();
      }

      if (AssertionUtils.isNullOrUndefined(this.machineOverviewMode) || this.machineOverviewMode === MachineOverviewViewMode.LISTVIEW) {
        this.getNotifications();
      }

      this.notificationsService.onNotificationAdded
        .pipe(
          takeUntil(this.unSubscribeOnViewDestroy),
          filter(
            (notification: Notification) =>
              AssertionUtils.isNullOrUndefined(this.currentGroup) ||
              this.currentGroup
                .getNestedChildMachineIds()
                .map((machineId: number) => machineId)
                .includes(notification.equipment.id)
          )
        )
        .subscribe((addedNotification: Notification) => this.addNotificationsToList(addedNotification));

      this.notificationsService.onNotificationMarkedAsRead
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((notificationMarkedAsRead: OnNotificationMarkedAsReadInterface) =>
          this.notificationsService.markNotificationsAsRead(this.notificationsList, notificationMarkedAsRead.readNotificationIds)
        );
    }
  }

  public setScale(originalHeight: number, wantedHeight: number): void {
    this.scaleImage = wantedHeight / originalHeight;
  }

  public markAllAsRead(): void {
    this.notificationsService.markAllAsRead(this.notificationsList).pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe();
  }

  public getDialogData(): any {
    return {options: this.priorities};
  }

  public getValue(): Observable<void> {
    return of();
  }

  public onOptionChanged(event: SelectionListItem[]): void {
    this.priorities = event;
    this.setEnabledPriorities();
    this.notificationsList = [];
    this.getNotifications();
  }

  public getNotifications(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.notificationsService
      .getNotificationsSlice(this.enabledPriorities, this.notificationsList.length, null, this.currentGroup?.id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((notifications: Notification[]) => {
        this.loading = false;
        this.addNotificationsToList(...notifications);
      });
  }

  public canShowUnreadCount(): boolean {
    return AssertionUtils.isNullOrUndefined(this.currentGroup) ? true : this.currentGroup.floorplanId === this.overviewGroupId;
  }

  private subscribeToGroupChanges(): void {
    this.group?.pipe(takeUntil(this.unSubscribeOnViewDestroy), distinctUntilChanged(), debounceTime(20)).subscribe((group: OverviewMachineGroup) => {
      this.currentGroup = group;
      this.notificationsList = [];
      this.loading = false;
      this.getNotifications();
    });
  }

  private setEnabledPriorities(): void {
    this.enabledPriorities = [];

    EnumUtils.getEnumNames(PriorityLevel).forEach((priorityLevel: PriorityLevel) => {
      const foundPriority = this.priorities.find((priority: SelectionListItem) => priority.name === `BMSCONFIG.EVENTS.PRIORITY_LEVEL.${priorityLevel}`);

      if (foundPriority?.value) {
        foundPriority.disabled = false;
        this.enabledPriorities.push(priorityLevel);
      }
    });

    if (this.enabledPriorities.length === 1) {
      this.priorities.find((priority: SelectionListItem) => priority.value).disabled = true;
    }
  }

  private addNotificationsToList(...notificationsToAdd: Notification[]): void {
    const uniqueNotifications = ArrayUtils.distinctBy([...notificationsToAdd, ...this.notificationsList], (notification: Notification) => notification.id);
    this.notificationsList.splice(0, this.notificationsList.length, ...uniqueNotifications);
    this.notificationsList.sort(Notification.compare);
  }
}
