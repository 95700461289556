import {Type} from '@angular/core';
import {ActivatedRouteSnapshot, LoadChildrenCallback, Route} from '@angular/router';
import {canActivatePermissionGuard} from '@application/security/can-activate-permission.guard';
import {Permission} from '@domain/profile/permission.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {RouteNode} from './route-node';

export class AuthorizedRouteFactory {
  public static createModuleRoute(routeNode: RouteNode, loadChildren: LoadChildrenCallback, data: any = {}): Route {
    return {
      path: routeNode.path,
      loadChildren,
      canActivate: [canActivatePermissionGuard],
      data: {
        requiredPermission: routeNode.requiredPermission,
        ...data
      }
    };
  }

  public static createComponentRoute(
    routeNode: RouteNode,
    component: Type<any>,
    data: any = {},
    requiredPermissionFactory: (route: ActivatedRouteSnapshot) => Permission = null,
    canActivateGuards: any[] = [],
    canDeavtivateGuards: any[] = []
  ): Route {
    return {
      path: routeNode.path,
      component,
      canActivate: AssertionUtils.isEmpty(canActivateGuards) ? [canActivatePermissionGuard] : canActivateGuards,
      canDeactivate: canDeavtivateGuards,
      data: AssertionUtils.isNullOrUndefined(requiredPermissionFactory) ? {requiredPermission: routeNode.requiredPermission, ...data} : {requiredPermissionFactory, ...data}
    };
  }
}
