import {ErrorHandler, Inject, Injectable, NgZone} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SwUpdate} from '@angular/service-worker';
import {isEmpty, isEqual, isUndefined, keys} from 'lodash-es';
import {NGXLogger} from 'ngx-logger';
import {ENVIRONMENT, EnvironmentVariables} from '../common/environment-variables';
import {ReloadRequiredComponent} from '../custom-components/reload-required/reload-required.component';
import {AppInsightsLoggingService} from '../custom-services/app-insights-logging/app-insights-logging.service';
import {AlertDialogData} from '../dialogs/alert-dialog/alert-dialog-data';
import {AlertDialogComponent} from '../dialogs/alert-dialog/alert-dialog.component';
import {AuthenticationError} from './authentication-error';
import {BackendError, BackendErrorSeverity} from './backend-error';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private ngZone: NgZone;
  private readonly reloadRequiredDialogReference = 'new-update-dialog';
  private readonly logger: NGXLogger;
  private readonly alertDialogReference = 'alert-dialog';
  private readonly swUpdate: SwUpdate;
  private readonly chunkFailedMessageRegex: RegExp = /Loading chunk \d+ failed/;
  private readonly dialog: MatDialog;

  public constructor(
    ngZone: NgZone,
    logger: NGXLogger,
    swUpdate: SwUpdate,
    dialog: MatDialog,
    private appInsightsLoggingService: AppInsightsLoggingService,
    @Inject(ENVIRONMENT) private environment: EnvironmentVariables
  ) {
    this.logger = logger;
    this.ngZone = ngZone;
    this.swUpdate = swUpdate;
    this.dialog = dialog;
  }

  public handleError(error: Error): void {
    if (this.chunkFailedMessageRegex.test(error.message)) {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      } else {
        this.showReloadRequiredDialog();
      }
    } else {
      this.captureException(error);
      if (this.shouldDisplayMessage(error)) {
        this.showAlertDialog(error);
      }
    }
  }

  private shouldDisplayMessage(error: Error): boolean {
    let result = true;
    if (error instanceof BackendError) {
      result = !isEqual(error.severity, BackendErrorSeverity.INFO);
    }
    return result;
  }

  private captureException(error: Error): void {
    if (!this.environment.isDebugMode && !(error instanceof AuthenticationError)) {
      this.appInsightsLoggingService.logException(error);
    } else {
      this.logger.error(error);
    }
  }

  private showReloadRequiredDialog(): void {
    if (isUndefined(this.dialog.getDialogById(this.reloadRequiredDialogReference))) {
      this.ngZone.run(() => {
        const dialogConfig: MatDialogConfig = {
          panelClass: this.alertDialogReference,
          autoFocus: true,
          id: this.reloadRequiredDialogReference,
          disableClose: true
        };

        this.dialog.open(ReloadRequiredComponent, dialogConfig);
      });
    }
  }

  private showAlertDialog(error: Error): void {
    if (isUndefined(this.dialog.getDialogById(this.alertDialogReference))) {
      this.ngZone.run(() => {
        const dialogConfig: MatDialogConfig = {
          id: this.alertDialogReference,
          panelClass: this.alertDialogReference,
          autoFocus: false
        };

        if (!isEmpty(keys(error))) {
          dialogConfig.data = AlertDialogData.createErrorData(error.name, error.message);
          this.dialog.open(AlertDialogComponent, dialogConfig);
        }
      });
    }
  }
}
