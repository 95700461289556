import {NgComponentOutlet} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {ApplicationLayoutService} from './application-layout.service';
import {DialogModule} from '../../dialogs/dialog.module';

@NgModule({
  imports: [NgComponentOutlet, MatSidenavModule, MatButtonModule, MatSidenavModule, MatMenuModule, MatIconModule, DialogModule],
  providers: [ApplicationLayoutService]
})
export class ApplicationLayoutModule {}
