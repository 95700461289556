import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TranslationModule} from '../../translation/translation.module';
import {SplitButtonModule} from '../split-button/split-button.module';
import {TabsMenuModule} from '../tabs-menu/tabs-menu.module';
import {EditorHeaderComponent} from './editor-header.component';

@NgModule({
  declarations: [EditorHeaderComponent],
  imports: [CommonModule, TabsMenuModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, TranslationModule, SplitButtonModule],
  exports: [EditorHeaderComponent]
})
export class EditorHeaderModule {}
