<header>
  <div class="header-left">
    <div class="title">
      <h3>{{ 'NOTIFICATIONS.MENU_NAME' | bmsTranslate }}</h3>
      <h3 *ngIf="(unreadCount | async) && canShowUnreadCount()">({{ unreadCount | async }})</h3>
    </div>
    <div *ngIf="(unreadCount | async) && !currentGroup?.id && notificationsList.length > 0" class="b2 url" (click)="markAllAsRead()">{{ 'NOTIFICATIONS.MARK_ALL_AS_READ' | bmsTranslate }}</div>
  </div>
  <vdw-dialog-button
    *appHasPermission="VIEW_NOTIFICATIONS_PERMISSION"
    #customSettingsButton
    (dialogDataChanged)="onOptionChanged($event)"
    [componentType]="notificationSettingsComponent"
    [dialogData]="getDialogData()"
  >
    <button
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      [class.open]="customSettingsButton.open"
      (click)="customSettingsButton.toggleDialog()"
      [matTooltip]="'GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS' | bmsTranslate"
    >
      <mat-icon svgIcon="settings"></mat-icon>
    </button>
  </vdw-dialog-button>
</header>
<app-notification-list *appHasPermission="VIEW_NOTIFICATIONS_PERMISSION" [notificationList]="notificationsList" (listScrolled)="getNotifications()" class="full-height"></app-notification-list>
<div *appHasNotPermission="VIEW_NOTIFICATIONS_PERMISSION" class="full-height flex-center">
  <vdw-overlay class="unauthorized-container" [titleKey]="'NOTIFICATIONS.UNAUTHORIZED_TITLE'" [hideActions]="true" [descriptionKey]="'NOTIFICATIONS.UNAUTHORIZED_DESCRIPTION'" [scale]="scaleImage">
  </vdw-overlay>
</div>
