import {AfterViewInit, Component, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import moment, {Moment} from 'moment';
import {debounceTime, map, startWith, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {DatepickerHeaderComponent} from '../datepicker-header/datepicker-header.component';
import {DATE_SELECT_FORMAT} from './date-select-formats';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'vdw-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: DATE_SELECT_FORMAT},
    {provide: DateAdapter, useClass: MomentDateAdapter}
  ]
})
export class DateRangePickerComponent extends BaseComponent implements AfterViewInit {
  public readonly DATE_PICKER_HEADER = DatepickerHeaderComponent;

  public readonly dateSelectForm = new FormGroup({
    startDate: new FormControl(moment()),
    endDate: new FormControl(moment(moment().add(4, 'd')))
  });

  @Output()
  public readonly dateRangeChanged = this.dateSelectForm.valueChanges.pipe(
    startWith(undefined),
    takeUntil(this.unSubscribeOnViewDestroy),
    debounceTime(100),
    map(() => this.dateSelectForm.value)
  );

  @ViewChild('datePicker')
  public datePicker: MatDatepicker<Moment>;

  @ViewChild('applyButton')
  public applyButton: MatButton;

  public get startDate(): Moment {
    return this.dateSelectForm.controls.startDate.value;
  }

  public get endDate(): Moment {
    return this.dateSelectForm.controls.endDate.value;
  }

  public ngAfterViewInit(): void {
    this.datePicker.closedStream.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      const startDateForm = this.dateSelectForm.controls.startDate;
      const endDateForm = this.dateSelectForm.controls.endDate;
      if (AssertionUtils.isNullOrUndefined(endDateForm.value)) {
        endDateForm.setValue(moment(startDateForm.value));
      }
    });

    this.datePicker.openedStream.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.addDayClickedEvents();
      this.addMonthNavigationEvents();
    });
  }

  public onMonthSelected(): void {
    this.addDayClickedEvents();
  }

  public goForwards(): void {
    this.moveRangeByDays(1);
  }

  public goBackwards(): void {
    this.moveRangeByDays(-1);
  }

  public clear(): void {
    this.dateSelectForm.controls.startDate.setValue(null);
    this.dateSelectForm.controls.endDate.setValue(null);
    this.datePicker.close();
  }

  public today(): void {
    this.dateSelectForm.controls.startDate.setValue(moment());
    this.dateSelectForm.controls.endDate.setValue(null);
    this.datePicker.close();
  }

  private moveRangeByDays(amount: number): void {
    const startDateForm = this.dateSelectForm.controls.startDate;
    const endDateForm = this.dateSelectForm.controls.endDate;
    startDateForm.setValue(moment(startDateForm.value.add(amount, 'd')));
    endDateForm.setValue(moment(endDateForm.value.add(amount, 'd')));
  }

  private addDayClickedEvents(): void {
    setTimeout(() => {
      const cells = document.querySelectorAll<HTMLElement>('.mat-calendar-body-cell');
      cells.forEach((cell: HTMLElement) => {
        cell.addEventListener('click', this.onDateCellClick.bind(this));
      });
    });
  }

  private onDateCellClick(): void {
    const hasRangeEndClass = !!document.querySelector('.mat-calendar-body-range-end');

    if (hasRangeEndClass) {
      this.applyButton._elementRef.nativeElement.click();
    }
  }

  private addMonthNavigationEvents(): void {
    setTimeout(() => {
      const monthNavigationButtons = document.getElementsByClassName('mdc-icon-button');
      const monthNavigationButtonsArray = Array.from(monthNavigationButtons) as HTMLElement[];
      monthNavigationButtonsArray.forEach((button: HTMLElement) => {
        button.addEventListener('click', this.onMonthNavigationClick.bind(this));
      });
    });
  }

  private onMonthNavigationClick(): void {
    this.addDayClickedEvents();
    this.addMonthNavigationEvents();
  }
}
