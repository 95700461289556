import {Gauge} from '@domain/machine/gauge';
import {MachineGroup} from '@domain/machine/machine-group';
import {MachineManufacturer} from '@domain/machine/machine-manufacturer';
import {MachineType} from '@domain/machine/machine-type.enum';
import {PlasticsMachineType} from '@domain/machine/plastics-machine-type';
import {AssertionUtils} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {MachineOverviewList} from './machine-overview-list';
import {PlasticMachineOverviewList} from './plastic-machine-overview-list';
import {TuftingMachineOverviewList} from './tufting-machine-overview-list';
import {WeavingMachineOverviewList} from './weaving-machine-overview-lists';

export class MachineOverviewListHelper {
  public static fromJSON(machineJSON: any): MachineOverviewList {
    let machineManufacturer: MachineManufacturer = null;
    let machineGroup: MachineGroup = null;

    if (!isNil(machineJSON.manufacturer)) {
      machineManufacturer = new MachineManufacturer(machineJSON.manufacturer.id, machineJSON.manufacturer.name);
    }

    if (!isNil(machineJSON.machineGroup)) {
      machineGroup = new MachineGroup(machineJSON.machineGroup.id, machineJSON.machineGroup.name);
    }

    const machineType: MachineType = MachineType[`${machineJSON.machineType}`] ?? MachineType.UNDEFINED;

    if (machineType === MachineType.WEAVING_MACHINE) {
      const widthInCm = machineJSON.widthInCm;
      const standardSpeed = machineJSON.standardSpeed;
      const numberOfShafts = machineJSON.numberOfShafts;
      const numberOfFeeders = machineJSON.numberOfFeeders;
      const standardEfficiency = machineJSON.standardEfficiency;

      return new WeavingMachineOverviewList(
        machineJSON.id,
        machineJSON.name,
        machineManufacturer,
        machineJSON.type,
        machineGroup,
        machineType,
        widthInCm,
        standardSpeed,
        numberOfShafts,
        numberOfFeeders,
        standardEfficiency
      );
    }

    if (machineType === MachineType.TUFT) {
      return new TuftingMachineOverviewList(
        machineJSON.id,
        machineJSON.name,
        machineManufacturer,
        machineJSON.type,
        machineGroup,
        machineType,
        machineJSON.widthInCm,
        machineJSON.standardSpeed,
        machineJSON.standardEfficiency,
        AssertionUtils.isNullOrUndefined(machineJSON.gauge) ? null : Gauge.fromJSON(machineJSON.gauge)
      );
    }

    if (machineType === MachineType.PLASTIC_MACHINE) {
      return new PlasticMachineOverviewList(
        machineJSON.id,
        machineJSON.name,
        machineGroup,
        machineJSON.standardSpeedInCycleTime,
        machineJSON.standardEfficiency,
        AssertionUtils.isNullOrUndefined(machineJSON.plasticMachineType) ? null : PlasticsMachineType.fromJSON(machineJSON.plasticMachineType)
      );
    }

    return new MachineOverviewList(machineJSON.id, machineJSON.name, machineManufacturer, machineJSON.type, machineGroup, machineType);
  }
}
