<mat-list class="quick-access-navigation">
  <mat-list-item *ngFor="let item of quickAccessNavBarItems" #topNavigationBarItemElement class="menu-item" (click)="chooseMenuItem($event, item)" [class.active]="isActive(item)">
    <mat-icon
      matBadge="{{ item.badge | async | badgeLabel }}"
      [class.small]="isSmallItem(item)"
      [class.search-available]="isSearchAvailable(item)"
      matBadgeColor="warn"
      matBadgeSize="small"
      mat-list-icon
      [svgIcon]="item.icon"
    ></mat-icon>
  </mat-list-item>
</mat-list>
