import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {getMappedColoredYarnSet} from '@application/helper/textile-data/colored-yarn-set/get-mapped-colored-yarn-set';
import {leastCommonMultiple} from '@application/helper/textile-data/colored-yarn-set/least-common-multiple';
import {ColoredYarnSet} from '@domain/textile-data/colored-yarn-set/colored-yarn-set';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {YarnType} from '@domain/textile-data/yarn-type/yarn-type';
import {CalculatedYarnConsumptionForProductionOrder} from '@domain/yarn-consumption/calculated-yarn-consumption-for-production-order';
import {YarnConsumptionWithColoredYarnSetId} from '@domain/yarn-consumption/yarn-consumption-with-colored-yarn-set-id';
import {ColoredYarnSets, COLORED_YARN_SETS} from '@infrastructure/http/colored-yarn-set/colored-yarn-sets';
import {RealtimeYarnConsumption, REALTIME_YARN_CONSUMPTION} from '@infrastructure/signalr/yarn-consumption/realtime-yarn-consumption';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {GridYarnTypesOfCreelPositionComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/grid-yarn-types-of-creel-position/grid-yarn-types-of-creel-position.component';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {OverviewListCreelPositionWithColorAndYarnTypes} from '@presentation/pages/textile-data/textile-data-detail/overview-list-creel-position-with-color-and-yarn-types';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {BaseComponent, ColDefBuilderFactoryService, Color, GridOptionsBuilderFactoryService, OverlayComponentParams} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {CellClassParams, ColDef, GridOptions, ICellRendererParams, ITooltipParams, RowHeightParams, ValueGetterParams} from 'ag-grid-community';
import {cloneDeep, each, find, isEmpty, isEqual, isNil, map, max, size, times} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {GridColorsOfCreelPositionComponent} from '../colored-yarn-set/add/grid-colors-of-creel-position/grid-colors-of-creel-position.component';
import {OverviewListYarnType} from '../yarn-type/overview/overview-list-yarn-type';
import {GridYarnConsumptionComponent} from './grid-yarn-consumption/grid-yarn-consumption.component';
import {TextileDataDetailDialogData} from './textile-data-detail-dialog-data.interface';

@Component({
  templateUrl: './textile-data-detail.component.html',
  styleUrls: ['./textile-data-detail.component.scss']
})
export class TextileDataDetailComponent extends BaseComponent implements OnInit {
  @ViewChild('creelPositionsGrid') public creelPositionsGrid: AgGridAngular;

  public textileData: any;
  public textileDataAmount: string;
  public listOfCreelPositions: OverviewListCreelPositionWithColorAndYarnTypes[];
  public gridOptionsForListOfCreelPositions: GridOptions;
  public creelMap: CreelMap;
  public showActiveCreelMap = false;
  private showYarnConsumption = false;
  private mappedColoredYarnSet: OverviewListColoredYarnSet;
  private readonly initialCreelMappingInfoClassName = 'initial-creel-mapping-info';

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: TextileDataDetailDialogData,
    private readonly repositionDialogComponent: RepositionDialogComponent,
    @Inject(COLORED_YARN_SETS) private readonly coloredYarnSets: ColoredYarnSets,
    @Inject(REALTIME_YARN_CONSUMPTION) private readonly realtimeYarnConsumption: RealtimeYarnConsumption,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {
    super();
    this.textileData = data.textileData;
    this.textileDataAmount = data.textileDataAmount;
    this.creelMap = data.creelMap;
    this.showActiveCreelMap = !isNil(this.creelMap);
    this.showYarnConsumption = isNil(data.showYarnConsumption) ? false : data.showYarnConsumption;
  }

  private static calculateRowHeight(creelPosition: OverviewListCreelPositionWithColorAndYarnTypes): number {
    let numberOfItemsInRow: number;
    const creelPositionForColors: OverviewListCreelPosition = creelPosition.creelPositionForColors;
    if (!isNil(creelPositionForColors)) {
      numberOfItemsInRow = size(creelPositionForColors.items);
    }
    const creelPositionForYarnTypes: OverviewListCreelPosition = creelPosition.creelPositionForYarnTypes;
    if (!isNil(creelPositionForYarnTypes)) {
      numberOfItemsInRow = max([numberOfItemsInRow, size(creelPositionForYarnTypes.items)]);
    }
    const verticalPadding = 32;
    const labelHeight = 20;
    return verticalPadding + numberOfItemsInRow * labelHeight;
  }

  public ngOnInit(): void {
    this.updateListOfCreelPositions();
    this.initializeGridOptionsForCreelPositions();

    if (this.showYarnConsumption) {
      this.connectRealtimeYarnConsumption();
    }
  }

  public getTextileDataIcon(): string {
    let icon: string;
    if (this.textileData instanceof OverviewListColorSet) {
      icon = 'color-sets-blue';
    } else if (this.textileData instanceof OverviewListColoredYarnSet) {
      icon = 'colored-yarn-sets-blue';
    } else if (this.textileData instanceof OverviewListYarnSet) {
      icon = 'yarn-sets-blue';
    }
    return icon;
  }

  public getTextileDataName(): string {
    return isNil(this.textileData) ? '' : this.textileData.name;
  }

  public canShowTextileDataAmount(): boolean {
    return !isNil(this.textileDataAmount);
  }

  public getTextileDataAmount(): string {
    return `(${this.textileDataAmount})`;
  }

  public onCreelMapViewModeChange(matButtonToggleChange: MatButtonToggleChange): void {
    this.showActiveCreelMap = !matButtonToggleChange.value;
    const coloredYarnSet = matButtonToggleChange.value ? this.textileData : this.mappedColoredYarnSet;

    this.setListOfCreelPositions(coloredYarnSet.overviewListColorSet.creelPositions.length, coloredYarnSet.overviewListColorSet, coloredYarnSet.overviewListYarnSet);
    this.creelPositionsGrid.api.redrawRows();
  }

  private isCreelPositionDifferentFromTheoreticalCreel(index: number): boolean {
    let reversedIndexPosition = null;

    if (this.hasCreelMap() && this.showActiveCreelMap) {
      reversedIndexPosition = this.textileData.overviewListColorSet.creelPositions.length - 1 - index;
    }

    return (
      this.hasCreelMap() &&
      this.showActiveCreelMap &&
      this.textileData.overviewListColorSet.creelPositions[reversedIndexPosition].position !== this.mappedColoredYarnSet.overviewListColorSet.creelPositions[reversedIndexPosition].position
    );
  }

  private connectRealtimeYarnConsumption(): void {
    this.getCalculatedYarnConsumption();
  }

  private getCalculatedYarnConsumption(): void {
    this.realtimeYarnConsumption
      .getCalculatedYarnConsumptionForProductionOrder()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((calculatedYarnConsumption: CalculatedYarnConsumptionForProductionOrder) => {
        const labelForTextAlignRightClass = 'align-content-end';

        const calculatedYarnConsumptionForColoredYarnSet = find(calculatedYarnConsumption.yarnConsumption, (yarnConsumption: YarnConsumptionWithColoredYarnSetId) =>
          isEqual(yarnConsumption.coloredYarnSetId, (this.textileData as OverviewListColoredYarnSet).id)
        );

        if (
          !isNil(calculatedYarnConsumptionForColoredYarnSet) &&
          !isNil(calculatedYarnConsumptionForColoredYarnSet.yarnConsumption) &&
          !isEmpty(calculatedYarnConsumptionForColoredYarnSet.yarnConsumption.creelPositions)
        ) {
          each(this.listOfCreelPositions, (creelPosition: OverviewListCreelPositionWithColorAndYarnTypes, index: number) => {
            const position = size(this.listOfCreelPositions) - index - 1;
            each(creelPosition.creelPositionForYarnTypes.items, (item: OverviewListYarnType) => {
              item.yarnConsumption = calculatedYarnConsumptionForColoredYarnSet.yarnConsumption.creelPositions[position].yarnConsumption;
            });
          });

          this.creelPositionsGrid.api.setGridOption('columnDefs', [
            ...this.getColumnDefsForListOfCreelPositions(),
            this.colDefBuilderFactoryService
              .getBuilder()
              .withValueGetter('data.creelPositionForYarnTypes')
              .withHeaderName('TEXTILE_DATA.YARN_TYPE.YARN_CONSUMPTION')
              .withCellRenderer(GridYarnConsumptionComponent)
              .withMaxWidth(200)
              .withMinWidth(200)
              .withCellClass(labelForTextAlignRightClass)
              .build()
          ]);
          this.creelPositionsGrid.api.sizeColumnsToFit();
        }
      });
  }

  private updateListOfCreelPositions(): void {
    let colorSet: OverviewListColorSet;
    let yarnSet: OverviewListYarnSet;
    let numberOfCreelPositions: number;

    if (this.textileData instanceof OverviewListColorSet) {
      colorSet = this.textileData;
      numberOfCreelPositions = size(colorSet.creelPositions);
      this.setListOfCreelPositions(numberOfCreelPositions, colorSet, null);
    } else if (this.textileData instanceof OverviewListColoredYarnSet) {
      if (isNil(this.textileData.overviewListYarnSet)) {
        this.coloredYarnSets
          .getById(this.textileData.id)
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe((coloredYarnSet: ColoredYarnSet) => {
            this.textileData.overviewListYarnSet = coloredYarnSet.overviewListYarnSet;
            this.setMappedColoredYarnSetAndListCreelPositions();
          });
      } else {
        this.setMappedColoredYarnSetAndListCreelPositions();
      }
    } else if (this.textileData instanceof OverviewListYarnSet) {
      yarnSet = this.textileData;
      numberOfCreelPositions = size(yarnSet.creelPositions);
      this.setListOfCreelPositions(numberOfCreelPositions, null, yarnSet);
    }
  }

  private setListOfCreelPositions(numberOfCreelPositions: number, colorSet: OverviewListColorSet, yarnSet: OverviewListYarnSet): void {
    const creelPositions = this.getCreelPositions(numberOfCreelPositions, colorSet, yarnSet);

    if (!isNil(yarnSet) && !isNil(colorSet)) {
      this.setListOfCreelPositionsWithYarnSetDefined(creelPositions);
    } else {
      this.listOfCreelPositions = cloneDeep(creelPositions);
    }
  }

  private getCreelPositions(numberOfCreelPositions: number, colorSet: OverviewListColorSet, yarnSet: OverviewListYarnSet): OverviewListCreelPositionWithColorAndYarnTypes[] {
    return times(numberOfCreelPositions, (index: number) => {
      const position = numberOfCreelPositions - index - 1;
      return {
        creelPositionForColors: isNil(colorSet) ? null : colorSet.creelPositions[position],
        creelPositionForYarnTypes: isNil(yarnSet) ? null : yarnSet.creelPositions[position]
      };
    });
  }

  private setListOfCreelPositionsWithYarnSetDefined(creelPositions: OverviewListCreelPositionWithColorAndYarnTypes[]): void {
    this.listOfCreelPositions = map(cloneDeep(creelPositions), (creelPosition: OverviewListCreelPositionWithColorAndYarnTypes) => {
      const commonMultiple = leastCommonMultiple(size(creelPosition.creelPositionForColors.items), size(creelPosition.creelPositionForYarnTypes.items));

      this.replicateCreelPositionForColorsUntilLeastCommonMultiple(creelPosition, commonMultiple);
      this.replicateCreelPositioForYarnTypesUntilLeastCommonMultiple(creelPosition, commonMultiple);

      return creelPosition;
    });
  }

  private replicateCreelPositionForColorsUntilLeastCommonMultiple(creelPosition: OverviewListCreelPositionWithColorAndYarnTypes, leastCommonMultipleNumber: number): void {
    const creelPositionForColorsToRepeat = cloneDeep(creelPosition.creelPositionForColors.items);
    const numberOfRepititionsForCreelPositionForColors = leastCommonMultipleNumber / size(creelPosition.creelPositionForColors.items);

    for (let i = 0; i < numberOfRepititionsForCreelPositionForColors - 1; i++) {
      creelPosition.creelPositionForColors.items.push(...creelPositionForColorsToRepeat);
    }
  }

  private replicateCreelPositioForYarnTypesUntilLeastCommonMultiple(creelPosition: OverviewListCreelPositionWithColorAndYarnTypes, leastCommonMultipleNumber: number): void {
    const creelPositionForYarnTypesToRepeat = cloneDeep(creelPosition.creelPositionForYarnTypes.items);
    const numberOfRepititionsForCreelPositionForYarnTypes = leastCommonMultipleNumber / size(creelPosition.creelPositionForYarnTypes.items);

    for (let i = 0; i < numberOfRepititionsForCreelPositionForYarnTypes - 1; i++) {
      creelPosition.creelPositionForYarnTypes.items.push(...creelPositionForYarnTypesToRepeat);
    }
  }

  private initializeGridOptionsForCreelPositions(): void {
    this.gridOptionsForListOfCreelPositions = this.gridOptionsBuilderFactoryService
      .getBuilder(this.getColumnDefsForListOfCreelPositions(), GridIdentifier.LIST_OF_CREEL_POSITIONS_FOR_TEXTILE_DATA_DETAIL, undefined, true)
      .withColumnMenu(['generalMenuTab'])
      .withGetRowHeight((params: RowHeightParams) => TextileDataDetailComponent.calculateRowHeight(params.data))
      .withOnGridSizeChanged(() => this.resizeGrid())
      .withNoRowsOverlay({
        titleParam: 'TEXTILE_DATA.MATERIAL_SET.CREEL_POSITION'
      } as OverlayComponentParams)
      .withLoadingOverlay()
      .withoutGetRowId()
      .withoutSorting()
      .withLockPinnedColumns()
      .withRowClassRules({
        [this.initialCreelMappingInfoClassName]: (params: CellClassParams) => this.isCreelPositionDifferentFromTheoreticalCreel(Number(params.node.id))
      })
      .build();
  }

  private resizeGrid(): void {
    this.repositionDialogComponent.repositionDialog();
  }

  private getColumnDefsForListOfCreelPositions(): ColDef[] {
    const colDefs: ColDef[] = [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withValueGetter('data.creelPositionForColors.position')
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.POSITION')
        .withCellRenderer(({node}: ICellRendererParams) => this.getPositionForRowIndex(node.rowIndex))
        .withTooltipValueGetter(({rowIndex}: ITooltipParams) => this.getPositionForRowIndex(rowIndex))
        .withWidth(120)
        .withSuppressSizeToFit()
        .withSuppressMovable()
        .build()
    ];

    if (this.textileData instanceof OverviewListColorSet || this.textileData instanceof OverviewListColoredYarnSet) {
      colDefs.push(
        this.colDefBuilderFactoryService
          .getBuilder()
          .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.COLOR_NAMES_HEADER')
          .withValueGetter((params: ValueGetterParams) => params.data.creelPositionForColors.items.map((color: Color) => color.name).join(', '), true)
          .withCellRenderer(GridColorsOfCreelPositionComponent)
          .build()
      );
    }
    if (this.textileData instanceof OverviewListColoredYarnSet || this.textileData instanceof OverviewListYarnSet) {
      colDefs.push(
        this.colDefBuilderFactoryService
          .getBuilder()
          .withHeaderName('TEXTILE_DATA.YARN_TYPE.YARN_TYPE')
          .withValueGetter((params: ValueGetterParams) => params.data.creelPositionForYarnTypes.items.map((yarnType: YarnType) => yarnType.name).join(', '), true)
          .withCellRenderer(GridYarnTypesOfCreelPositionComponent, (params: CellClassParams) => {
            return {
              isCreelPositionDifferentFromTheoreticalCreel: this.isCreelPositionDifferentFromTheoreticalCreel(Number(params.node.id))
            };
          })
          .build()
      );
    }
    return colDefs;
  }

  private hasCreelMap(): boolean {
    return !isNil(this.creelMap);
  }

  private setMappedColoredYarnSetAndListCreelPositions(): void {
    if (this.hasCreelMap()) {
      this.mappedColoredYarnSet = getMappedColoredYarnSet(this.textileData, this.creelMap, 'asc');
      this.setListOfCreelPositions(this.mappedColoredYarnSet.overviewListColorSet.creelPositions.length, this.mappedColoredYarnSet.overviewListColorSet, this.mappedColoredYarnSet.overviewListYarnSet);
    } else {
      this.setListOfCreelPositions(this.textileData.overviewListColorSet.creelPositions.length, this.textileData.overviewListColorSet, this.textileData.overviewListYarnSet);
    }
  }

  private getPositionForRowIndex(rowIndex: number): string {
    return `${this.listOfCreelPositions.length - rowIndex}`;
  }
}
