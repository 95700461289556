<div class="wrapper flex-row flex-space-between gap-16">
  <div class="flex-row flex-end-center gap-16 overflow-hidden">
    <button mat-icon-button class="back" *ngIf="hasBackButton()" (click)="backButtonClicked()">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
    <div class="avatar" *ngIf="svgIcon">
      <mat-icon [svgIcon]="svgIcon"></mat-icon>
    </div>
    <div class="flex-column flex-1 overflow-hidden">
      <h3 class="flex-row">
        <span *ngIf="action" class="font-weight-semibold no-wrap">{{ action + '&nbsp;' }}</span>
        <vdw-ellipsis-label [text]="title" matTooltipPosition="below" class="overflow-hidden"></vdw-ellipsis-label>
      </h3>
      <span *ngIf="extraInformationTitle" class="b1 font-weight-semibold">{{ extraInformationTitle }}</span>
      <p *ngIf="canShowAmount()" class="b1 font-weight-semibold">({{ amount }})</p>
    </div>
  </div>

  <div class="skeleton-wrapper" [class.skeleton]="isLoading" [@fadeIn]="isLoading">
    <div class="flex-row flex-end-center gap-16">
      <vdw-invalid-form-message *ngIf="invalidFormMessageError; else customInvalidErrorMessage"></vdw-invalid-form-message>
      <ng-template #customInvalidErrorMessage>
        <vdw-invalid-form-message *ngIf="showCustomErrorMessage" [message]="customErrorMessage | bmsTranslate"></vdw-invalid-form-message>
      </ng-template>
      <div *ngIf="canShowSettingsButton()" class="button-skeleton-wrapper standard-btn">
        <button
          mat-icon-button
          class="mat-icon-action-button-lg-with-border disable-focus"
          [tabindex]="headerTabIndex.tabIndexCustomSettings"
          [matTooltip]="settingsTooltip ?? '' | bmsTranslate"
          [matTooltipDisabled]="!settingsTooltip"
          (click)="settingsButtonClicked()"
        >
          <mat-icon svgIcon="settings"></mat-icon>
        </button>
      </div>
      <ng-content></ng-content>

      <mat-divider vertical *ngIf="canShowDivider()" class="vertical-divider"></mat-divider>

      <div *ngIf="canShowMoreButton()" class="button-skeleton-wrapper standard-btn">
        <button
          mat-icon-button
          class="mat-icon-action-button-lg-with-border disable-focus more-button"
          #optionMenuTrigger
          [matMenuTriggerFor]="optionsMenu"
          (mouseenter)="mouseEnterOptionButton()"
          (mouseleave)="mouseLeaveOptionButton()"
        >
          <mat-icon svgIcon="more"></mat-icon>
        </button>
      </div>
      <mat-menu #optionsMenu="matMenu" yPosition="below" class="mat-elevation-z0" [hasBackdrop]="false">
        <div (mouseenter)="mouseEnterOptionButton()" (mouseleave)="mouseLeaveOptionButton()">
          <button *ngIf="hasDuplicateButton()" mat-menu-item (click)="duplicateButtonClicked()">{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.DUPLICATE' | bmsTranslate }}</button>
          <button *ngIf="hasDeleteButton()" mat-menu-item [disabled]="isInUse" (click)="deleteButtonClicked()">{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.DELETE' | bmsTranslate }}</button>
          <button *ngFor="let headerAction of headerActions" mat-menu-item [disabled]="headerAction.disabled" (click)="headerActionClicked($event, headerAction)">{{ headerAction.label }}</button>
        </div>
      </mat-menu>
      <div *ngIf="canShowCancelButton()" class="button-skeleton-wrapper standard-btn">
        <button mat-stroked-button (click)="cancelButtonClicked()" [tabindex]="headerTabIndex.tabIndexCancel">
          {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.CANCEL' | bmsTranslate }}
        </button>
      </div>
      <div *ngIf="canShowSaveButton() || canShowInUseButton()" class="button-skeleton-wrapper raised-btn">
        <vdw-progress-button
          *ngIf="canShowSaveButton()"
          [tabIndex]="headerTabIndex.tabIndexSave"
          [disabled]="locked"
          [inProgress]="saving"
          (progressClick)="save(defaultSaveType)"
          [hasIcon]="hasSaveMenu()"
          [label]="getSaveButtonTranslationKey() | bmsTranslate: {action: actionText | bmsTranslate}"
        >
          <div
            *ngIf="hasSaveMenu()"
            #saveMenuTrigger
            class="full-height flex-center save-menu-trigger"
            [ngClass]="{'display-save-menu': locked, 'hide-icon': saving}"
            [matMenuTriggerFor]="showSaveMenu()"
            (click)="$event.stopPropagation()"
            (mouseenter)="mouseEnterSaveButton()"
            (mouseleave)="mouseLeaveSaveButton()"
          >
            <mat-icon svgIcon="chevron-down"></mat-icon>
          </div>
        </vdw-progress-button>
        <button mat-stroked-button color="warn" *ngIf="canShowInUseButton()" (click)="inUseButtonClicked()">
          <mat-icon svgIcon="error"></mat-icon>
          {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ALREADY_USED' | bmsTranslate }}
        </button>
      </div>
      <mat-menu #saveMenu="matMenu" yPosition="below" class="mat-elevation-z0" [hasBackdrop]="false">
        <div (mouseenter)="mouseEnterSaveButton()" (mouseleave)="mouseLeaveSaveButton()">
          <button *ngIf="hasSaveMenuButton()" mat-menu-item (click)="save(SAVE)">{{ actionText | bmsTranslate }}</button>
          <button *ngIf="hasSaveAndCloseMenuButton()" mat-menu-item (click)="save(SAVE_AND_CLOSE)">
            {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CLOSE' | bmsTranslate: {action: actionText | bmsTranslate} }}
          </button>
          <button *ngIf="hasSaveAndCreateNewMenuButton()" mat-menu-item (click)="save(SAVE_AND_CREATE_NEW)">
            {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CREATE_NEW' | bmsTranslate: {action: actionText | bmsTranslate} }}
          </button>
        </div>
      </mat-menu>

      <div *ngIf="canShowClose">
        <button *ngIf="!closeEvent.observed" mat-icon-button [mat-dialog-close] class="mat-icon-action-button-sm-without-border">
          <mat-icon svgIcon="close"></mat-icon>
        </button>

        <button *ngIf="closeEvent.observed" mat-icon-button class="mat-icon-action-button-sm-without-border" (click)="closeClicked()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
