import {ChangeDetectorRef, Injectable, Type} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {ArrowPosition} from '../../dialogs/dialog-reposition/arrow-position.enum';
import {HoverDialogBuilderService} from '../../dialogs/hover-dialog/hover-dialog-builder.service';
import {MenuItemEntry} from '../menu-tree-view/menu-item/menu-item-entry.interface';

@Injectable({providedIn: 'root'})
export class ApplicationLayoutService extends BaseComponent {
  public sidebarIcon: string;
  public resizeHovered = false;
  public matDrawer: MatDrawer;
  public hoverDialogOpened: boolean;
  public menuItems: MenuItemEntry[];
  public sidebarHoverButton: HTMLElement;
  public hideFavorites = true;

  public sidebarType: Type<any>;
  public navigationHeaderType: Type<any>;

  public constructor(private readonly hoverDialogBuilderService: HoverDialogBuilderService) {
    super();
  }

  public toggle(): void {
    this.matDrawer?.toggle();
  }

  public isDrawerOpened(): boolean {
    return this.matDrawer?.opened ?? false;
  }

  public openHoverDialog(hoveredElement: HTMLElement, otherElements: HTMLElement[], data: any, component: Type<any>, changeDetectorRef: ChangeDetectorRef): void {
    if (this.hoverDialogOpened || this.isDrawerOpened()) {
      return;
    }

    this.hoverDialogOpened = true;
    this.sidebarIcon = 'solid-keyboard-double-arrow-right';

    this.hoverDialogBuilderService
      .getBuilderWithHoverElements(hoveredElement, otherElements)
      .withClass('bms-theme')
      .withWidth('240px')
      .withHeight('calc(100vh - 80px)')
      .withoutBackdrop()
      .openAtElement(hoveredElement, ArrowPosition.TOP, component, data, changeDetectorRef, false)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        this.hoverDialogOpened = false;
        this.sidebarIcon = this.isDrawerOpened() ? 'solid-keyboard-double-arrow-left' : 'solid-menu';
      });
  }

  public getFlattenedMenuItems(): MenuItemEntry[] {
    return this.menuItems.flatMap((entry: MenuItemEntry) => this.flattenMenuItems(entry));
  }

  private flattenMenuItems(menuItem: MenuItemEntry): MenuItemEntry[] {
    const menuItems = [menuItem];

    if (!AssertionUtils.isEmpty(menuItem.childEntries)) {
      menuItems.push(...menuItem.childEntries.flatMap((entry: MenuItemEntry) => this.flattenMenuItems(entry)));
    }

    return menuItems;
  }

  public getMenuItemPath(itemToFind: MenuItemEntry): MenuItemEntry[] {
    return this.menuItems.flatMap((entry: MenuItemEntry) => this.findMenuItemPath(entry, itemToFind, []));
  }

  public findParentEntry(group: MenuItemEntry): MenuItemEntry {
    return this.menuItems?.map((groupEntry: MenuItemEntry) => this.getParentOfChildEntry(groupEntry, group))?.find((groupEntry: MenuItemEntry) => !AssertionUtils.isNullOrUndefined(groupEntry));
  }

  private findMenuItemPath(parentMenuItem: MenuItemEntry, itemToFind: MenuItemEntry, path: MenuItemEntry[]): MenuItemEntry[] {
    if (AssertionUtils.isEmpty(parentMenuItem.childEntries)) {
      return [];
    }

    if (parentMenuItem.childEntries.includes(itemToFind)) {
      return [...path, parentMenuItem];
    }

    return parentMenuItem.childEntries.flatMap((entry: MenuItemEntry) => this.findMenuItemPath(entry, itemToFind, [parentMenuItem]));
  }

  private getParentOfChildEntry(startGroup: MenuItemEntry, childGroup: MenuItemEntry): MenuItemEntry {
    if (startGroup.childEntries?.find((group: MenuItemEntry) => group.title === childGroup.title && group.link === childGroup.link)) {
      return startGroup;
    }

    return startGroup.childEntries?.map((group: MenuItemEntry) => this.getParentOfChildEntry(group, childGroup))?.find((group: MenuItemEntry) => !AssertionUtils.isNullOrUndefined(group));
  }
}
