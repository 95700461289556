import {Component, Inject, Input} from '@angular/core';
import {RESPONSIVENESS_VIEW_MODE, ResponsivenessViewMode} from '../../common/responsiveness/responsiveness-view.mode';

@Component({
  selector: 'vdw-invalid-form-message',
  templateUrl: './invalid-form-message.component.html',
  styleUrls: ['./invalid-form-message.component.scss']
})
export class InvalidFormMessageComponent {
  @Input() public message = 'ANGULAR_COMPONENT_LIBRARY.FORM_MESSAGE.CHECK_INVALID_FIELDS';
  @Input() public compact = false;
  @Input() public link = null;
  @Input() public linkTranslationKey = 'GENERAL.ACTIONS.LOAD';

  public constructor(@Inject(RESPONSIVENESS_VIEW_MODE) public responsivenessViewMode: ResponsivenessViewMode) {}

  public isNotMobile(): boolean {
    return !this.responsivenessViewMode.isPhone;
  }
}
