import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {GridColorPreviewCellRendererParams} from '@presentation/pages/textile-data/color/overview/grid-color-preview/grid-color-preview-cell-renderer-params';
import {AssertionUtils, Color} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {IRowNode} from 'ag-grid-community';

@Component({
  selector: 'app-grid-color-preview',
  templateUrl: './grid-color-preview.component.html',
  styleUrls: ['./grid-color-preview.component.scss']
})
export class GridColorPreviewComponent implements ICellRendererAngularComp {
  public showRGBValue = false;
  public color: Color;
  public node: IRowNode;
  public hasMultipleSelection = false;
  public isRuleCondition = false;
  public enableClickableName = false;
  public editPageUrl: string;

  public constructor(private readonly router: Router) {}

  public agInit(params: GridColorPreviewCellRendererParams): void {
    this.node = params.node;
    this.color = this.getColorFromParams(params);
    this.showRGBValue = params.showRGBValue;
    this.hasMultipleSelection = params?.hasMultipleSelection;
    this.isRuleCondition = params?.isRuleCondition;
    this.enableClickableName = params?.enableClickableName;
    this.editPageUrl = params?.editPageUrl?.replace(':id', params.id ?? params.data.id.toString());
  }

  public refresh(params: any): boolean {
    return false;
  }

  public getReadableLabelForRGB(): string {
    if (!AssertionUtils.isNullOrUndefined(this.color.rgb)) {
      return Object.values(this.color.rgb).join(', ');
    }
    return '';
  }

  public canShowColorPreview(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.color);
  }

  private getColorFromParams(params: GridColorPreviewCellRendererParams): Color {
    if (params.data instanceof Color) {
      return params.data;
    }
    return params.value;
  }
}
