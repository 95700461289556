<div *ngIf="isRoot" class="header-container">
  <div class="header">{{ entry.title }}</div>
  <div *ngIf="isFavorites" class="icon-container">
    <mat-icon
      class="arrow-right"
      [style.min-width.px]="iconWidthInPx"
      (click)="menuTreeViewContext.menuItemClicked(entry, $event)"
      [svgIcon]="entry.isExpanded ? 'solid-expand-more' : 'solid-keyboard-arrow-right'"
    ></mat-icon>
  </div>
</div>
<vdw-menu-item *ngIf="!isRoot" [entry]="entry" [iconWidthInPx]="iconWidthInPx" [isFavorites]="isFavorites" (menuItemClicked)="menuTreeViewContext.menuItemClicked(entry, $event)"></vdw-menu-item>
<div class="child-entries" [class.root-entries]="isRoot" *ngIf="(!isFavorites && (entry.isExpanded || isRoot)) || (isFavorites && entry.isExpanded)">
  <vdw-menu-tree-view *ngFor="let childEntry of entry.childEntries" [entry]="childEntry" [iconWidthInPx]="iconWidthInPx" [isFavorites]="isFavorites"></vdw-menu-tree-view>
</div>
