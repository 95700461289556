<div class="mobile-filter full-width flex-column">
  <div *ngIf="isBrowserSupported" class="toggle-container">
    <vdw-toggle-button
      class="push-notifications-toggle"
      [(checked)]="toggleValue"
      label="NOTIFICATIONS.PUSH_NOTIFICATIONS"
      [isInteractable]="!isSubscriptionProcessing"
      (afterCheckedChange)="toggleSubscription()"
    ></vdw-toggle-button>
    <button mat-icon-button class="mat-icon-action-button-sm-without-border" [mat-dialog-close]="undefined">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div class="header" fxLayoutAlign="space-between">
    <h4>{{ 'NOTIFICATIONS.SHOW_PRIORITY_LEVELS' | bmsTranslate }}</h4>
    <button *ngIf="!isBrowserSupported" mat-icon-button class="mat-icon-action-button-sm-without-border" [mat-dialog-close]="undefined">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <vdw-selection-list class="selection-list" (optionsChanged)="onOptionChanged($event)" [options]="options"></vdw-selection-list>
  <button class="submit-button" mat-flat-button (click)="confirm()">{{ 'GENERAL.ACTIONS.CONFIRM' | bmsTranslate }}</button>
</div>
