<section fxLayout="column" class="full-height">
  <vdw-header [title]="'GENERAL.SEARCH' | bmsTranslate" svgIcon="select-blue" [canShowClose]="true" (closeEvent)="cancelObjectSelection()"></vdw-header>
  <article class="dialog-content" fxLayout="column" fxLayoutGap="16px" fxFlex>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <h4 class="font-weight-semibold" fxFlex>{{ subtitle }}</h4>
      <vdw-toggle-button *ngIf="canShowLatestVersionToggle()" (checkedChange)="showOnlyLatestVersionChanged($event)"></vdw-toggle-button>
      <vdw-filter *ngIf="showSearchBar" (filteredTextChanged)="filterObjects($event)"></vdw-filter>
    </div>
    <ag-grid-angular #grid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfObjects" [rowData]="listOfObjects"></ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canChooseSelectedObject()" [centered]="true" (chooseEvent)="chooseSelectedObject()" (cancelEvent)="cancelObjectSelection()"></vdw-footer>
</section>
