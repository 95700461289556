import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridOptions} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {GridOptionsBuilder} from '../../grids/grid-options-builder';
import {GridOptionsBuilderFactoryService} from '../../grids/grid-options-builder-factory.service';
import {CrudOverviewButtonConfig} from './interfaces/crud-overview-button-config.interface';
import {CrudOverviewDataConfig} from './interfaces/crud-overview-data-config.interface';
import {CrudOverviewExtraActionConfig} from './interfaces/crud-overview-extra-action-config.interface';

export class CrudOverviewDataBuilder<T> {
  private config: CrudOverviewDataConfig<T> = {onlyShowSelected: false};
  private deleteButton: CrudOverviewButtonConfig = {title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.DELETE', icon: 'solid-delete', withTooltip: true};
  private editButton: CrudOverviewButtonConfig = {title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EDIT', icon: 'solid-edit', onlyEnabledOnSingleRowSelection: true, withTooltip: true};
  private duplicateButton: CrudOverviewButtonConfig = {
    title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.DUPLICATE',
    icon: 'solid-file-copy',
    onlyEnabledOnSingleRowSelection: true,
    withTooltip: true
  };

  private exportButton: CrudOverviewButtonConfig = {
    title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EXPORT',
    icon: 'solid-download',
    withTooltip: true,
    onClick: (grid: AgGridAngular) => grid?.api?.exportDataAsCsv()
  };

  public constructor(private readonly gridOptionsBuilderFactory: GridOptionsBuilderFactoryService) {
    this.config.buttons = [];
    this.config.extraActions = [];
    this.config.selectedObjects = [];
    this.config.isObjectSelection = false;
  }

  public build(): CrudOverviewDataConfig<T> {
    return this.config;
  }

  public withGridOptions(colDefs: ColDef[], gridIdentifier: string, data: T[], getOptions?: (builder: GridOptionsBuilder) => GridOptions): this {
    getOptions ??= (builder: GridOptionsBuilder): GridOptions => builder.build();
    this.config.gridOptions = getOptions(this.gridOptionsBuilderFactory.getCrudBuilder(colDefs, gridIdentifier, data));

    if (AssertionUtils.isNullOrUndefined(this.config.rowData)) {
      this.config.rowData = data;
    }

    return this;
  }

  //dataObservable should already have takeUntil implemented
  public withRowData(dataObservable: Observable<T[]>): this {
    dataObservable.subscribe((data: T[]) => (this.config.rowData = data));
    return this;
  }

  public withExtraActions(extraActionConfig: CrudOverviewExtraActionConfig[]): this {
    this.config.extraActions = extraActionConfig;
    return this;
  }

  public withDefaultButtons(): this {
    this.config.buttons.unshift(...[this.editButton, this.duplicateButton, this.deleteButton, this.exportButton]);
    return this;
  }

  public withObjectSelection(selectedObjects: T[]): this {
    this.config.isObjectSelection = true;
    this.config.selectedObjects = selectedObjects;
    return this;
  }

  public withHeader(title: string): this {
    this.config.headerTitle = title;
    return this;
  }

  public withNewClicked(onClick: (event: MouseEvent) => void): this {
    this.config.withNewClicked = onClick;
    return this;
  }

  public withEdit(onClick: (event: AgGridAngular) => void): this {
    this.editButton.onClick = onClick;
    return this;
  }

  public withDuplicate(onClick: (event: AgGridAngular) => void): this {
    this.duplicateButton.onClick = onClick;
    return this;
  }

  public withDelete(onClick: (event: AgGridAngular) => void, allowMultiple: boolean = true): this {
    this.deleteButton.onClick = onClick;
    this.deleteButton.onlyEnabledOnSingleRowSelection = !allowMultiple;
    return this;
  }

  public withCustomExport(onClick: (event: AgGridAngular) => void): this {
    this.exportButton.onClick = onClick;
    return this;
  }
}
