import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {isNil} from 'lodash-es';
import {DrawingImage} from './drawing-image';

export class TftDrawing extends Drawing {
  public constructor(id: number, name: string, image: DrawingImage, shape: string, repeated: boolean, sizeInKB?: number) {
    super(id, name, DrawingType.TFT, image, null, shape, false, repeated, [], null, [], false, null, sizeInKB);
  }

  public static fromJSON(drawingJSON: any): TftDrawing {
    const image: DrawingImage = isNil(drawingJSON.image)
      ? {}
      : {
          id: drawingJSON.image.id || null,
          data: drawingJSON.image.data || null
        };

    return new TftDrawing(drawingJSON.id, drawingJSON.name, image, drawingJSON.shape, drawingJSON.repeated, drawingJSON.sizeInKB);
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      imageId: this.image.id,
      drawingType: `${DrawingType[this.drawingType]}`,
      shape: this.shape
    } as any as JSON;
  }
}
