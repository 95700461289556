import {inject, InjectionToken} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Routes} from '@angular/router';
import {AuthorizedRouteFactory} from '@application/helper/routing/authorized-route-factory';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {DynamicPathGuard} from '@application/helper/routing/routing-guards/dynamic-path.guard';
import {canActivateDefaultPathGuard} from '@application/security/can-activate-default-path.guard';
import {canActivatePermissionGuard} from '@application/security/can-activate-permission.guard';
import {canActivateViaAuthGuard} from '@application/security/can-activate-via-auth-guard';
import {canLoadViaAuthGuard} from '@application/security/can-load-via-auth-guard';
import {LoadingFileComponent} from '@presentation/components/loading-file/loading-file.component';
import {LoggerComponent} from '@presentation/components/logger/logger.component';
import {UnauthorizedPageComponent} from '@presentation/pages/status-codes/401/unauthorized-page.component';
import {NotFoundPageComponent} from '@presentation/pages/status-codes/404/not-found-page.component';

export const externalUrlProvider = new InjectionToken('ExternalUrlRedirectResolver');

export const routes: Routes = [
  {path: '', pathMatch: 'full', canActivate: [canActivateDefaultPathGuard], component: UnauthorizedPageComponent},
  {
    path: RouteUtils.paths.login.path,
    loadChildren: (): any => import('@presentation/pages/login/login.module').then((m: any) => m.LoginModule)
  },
  {
    path: RouteUtils.paths.register.path,
    loadChildren: (): any => import('@presentation/pages/register/register.module').then((m: any) => m.RegisterModule)
  },
  {
    path: RouteUtils.paths.texStyle.path,
    loadChildren: (): any => import('@presentation/pages/textile-data/textile-data.module').then((m: any) => m.TextileDataModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.texStyle.requiredPermission
    }
  },
  {
    path: RouteUtils.paths.texFab.path,
    loadChildren: (): any => import('@presentation/pages/texfab/texfab.module').then((m: any) => m.TexfabModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.texFab.requiredPermission
    }
  },
  {
    path: RouteUtils.paths.texState.path,
    loadChildren: (): any => import('@presentation/pages/machine-overview/machine-overview.module').then((m: any) => m.MachineOverviewModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.texState.requiredPermission,
      permissionToCheckSettings: RouteUtils.paths.texState.editMachine.requiredPermission
    }
  },
  {
    path: RouteUtils.paths.texEdit.path,
    loadChildren: (): any => import('@presentation/pages/texedit/texedit.module').then((m: any) => m.TexeditModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.texEdit.requiredPermission
    }
  },
  {
    path: RouteUtils.paths.texBi.path,
    loadChildren: (): any => import('@presentation/pages/texbi/texbi.module').then((m: any) => m.TexbiModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.texBi.requiredPermission
    }
  },
  {
    path: RouteUtils.paths.profile.path,
    loadChildren: (): any => import('@presentation/pages/profile/profile.module').then((m: any) => m.ProfileModule),
    canLoad: [canLoadViaAuthGuard]
  },
  {path: RouteUtils.paths.unauthorized.path, component: UnauthorizedPageComponent},
  {
    path: RouteUtils.paths.notFound.path,
    canActivate: [canActivateViaAuthGuard],
    component: NotFoundPageComponent
  },
  {
    path: RouteUtils.paths.texBot.path,
    loadChildren: (): any => import('@presentation/pages/texbot/texbot.module').then((m: any) => m.TexbotModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.texBot.requiredPermission
    }
  },
  AuthorizedRouteFactory.createModuleRoute(RouteUtils.paths.utilities, (): any => import('@presentation/pages/utilities/utilities.module').then((m: any) => m.UtilitiesModule)),
  {
    path: RouteUtils.paths.help.path,
    loadChildren: (): any => import('@presentation/pages/help/help.module').then((m: any) => m.HelpModule),
    canLoad: [canLoadViaAuthGuard]
  },
  {path: RouteUtils.paths.loadingFile.path, component: LoadingFileComponent},
  {
    path: RouteUtils.paths.logger.path,
    component: LoggerComponent,
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.logger.requiredPermission
    }
  },
  {
    path: RouteUtils.paths.processes.path,
    loadChildren: (): any => import('@presentation/pages/background-jobs/background-jobs.module').then((m: any) => m.BackgroundJobsModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.processes.requiredPermission
    }
  },
  {
    path: RouteUtils.paths.settings.path,
    loadChildren: (): any => import('@presentation/pages/settings/settings.module').then((m: any) => m.SettingsModule),
    canLoad: [canLoadViaAuthGuard],
    canActivate: [canActivatePermissionGuard],
    data: {
      requiredPermission: RouteUtils.paths.settings.requiredPermission
    }
  },
  {path: '**', canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => inject(DynamicPathGuard).canActivate(route, state)], component: NotFoundPageComponent}
];
