import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {NavigationState} from '@domain/navigation/navigation-state.enum';
import {ApplicationLayoutService, AssertionUtils, BaseComponent} from '@vdw/angular-component-library';
import {AppComponent} from '../../../app.component';
import {SideNavigationStateService} from '../side-navigation-state.service';
import {NavigationComponent} from '../navigation-component/navigation.component';

@Component({
  selector: 'app-navigation-hover-area',
  templateUrl: './navigation-hover-area.component.html',
  styleUrls: ['./navigation-hover-area.component.scss']
})
export class NavigationHoverAreaComponent extends BaseComponent implements AfterViewInit {
  public hoverEntered: boolean;
  public viewInitialized: boolean;
  @ViewChild('hoverArea') public hoverArea: ElementRef<HTMLDivElement>;

  private readonly HOVER_TIMEOUT = 100;

  public constructor(
    private readonly application: AppComponent,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public readonly applicationLayoutService: ApplicationLayoutService,
    private readonly sideNavigationState: SideNavigationStateService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
    }, 200);
  }

  public areaEntered(): void {
    this.hoverEntered = true;

    setTimeout(() => {
      if (this.hoverEntered) {
        const data = this.application.companyData;
        this.applicationLayoutService.openHoverDialog(this.applicationLayoutService.sidebarHoverButton, [this.hoverArea.nativeElement], data, NavigationComponent, this.changeDetectorRef);
      }
    }, this.HOVER_TIMEOUT);
  }

  public areaLeft(): void {
    this.hoverEntered = false;
  }

  public getSidebarOpened(): boolean {
    return AssertionUtils.isNullOrUndefined(this.applicationLayoutService.matDrawer)
      ? this.sideNavigationState.getCurrentNavigationState() === NavigationState.OPEN
      : this.applicationLayoutService.isDrawerOpened();
  }
}
