import {DimensionsInPx} from '@domain/dimensions-in-px';
import {CutbarsLength} from '@domain/production-schedule/cutbars-length';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {isNil} from 'lodash-es';
import {DrawingImage} from './drawing-image';

export class EpDrawing extends Drawing {
  private _cutbarsLength: CutbarsLength;
  private _epId: number;

  public constructor(
    id: number,
    name: string,
    image: DrawingImage,
    dimensionsInPixels: DimensionsInPx,
    cutbarsLength: CutbarsLength,
    shape: string,
    used: boolean,
    repeated: boolean,
    epId: number,
    sizeInKB?: number
  ) {
    super(id, name, DrawingType.EP, image, dimensionsInPixels, shape, used, repeated, [], null, [], false, null, sizeInKB);
    this._cutbarsLength = cutbarsLength;
    this._epId = epId;
  }

  public static fromJSON(drawingJSON: any): EpDrawing {
    const image: DrawingImage = isNil(drawingJSON.image)
      ? {}
      : {
          id: drawingJSON.image.id || null,
          data: drawingJSON.image.data || null
        };

    return new EpDrawing(
      drawingJSON.id,
      drawingJSON.name,
      image,
      {
        widthInPx: drawingJSON.dimensionsInPx.widthInPx,
        heightInPx: drawingJSON.dimensionsInPx.heightInPx
      },
      null,
      drawingJSON.shape,
      false,
      drawingJSON.repeated,
      drawingJSON.epId,
      drawingJSON.sizeInKB
    );
  }

  public get cutbarsLength(): CutbarsLength {
    return this._cutbarsLength;
  }

  public get epId(): number {
    return this._epId;
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      imageId: this.image.id,
      drawingType: `${DrawingType[this.drawingType]}`,
      shape: this.shape
    } as any as JSON;
  }
}
