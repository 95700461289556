import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslationModule} from '../../translation/translation.module';
import {ToggleButtonComponent} from './toggle-button.component';

@NgModule({
  declarations: [ToggleButtonComponent],
  imports: [CommonModule, MatSlideToggleModule, TranslationModule],
  exports: [ToggleButtonComponent]
})
export class ToggleButtonModule {}
