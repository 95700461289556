<section fxLayout="column" fxFlex="100">
  <div class="logo" fxLayoutAlign="center">
    <img src="../../../assets/images/logo.svg" alt="logo" />
    <button mat-icon-button fxFlexAlign="center" (click)="closeNavigation()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxFlex class="link-container">
    <div class="scrollable-links" fxLayout="column" fxFlex="100">
      <mat-list class="navigation" fxLayout="column" fxFlex.gt-xs="noshrink" fxLayoutAlign="start" fxLayoutAlign.gt-xs="start center">
        <div *ngFor="let item of navigationBarItems">
          <mat-list-item (click)="chooseMenuItem($event, item)" [class.active]="isActive(item)">
            <mat-icon mat-list-icon [svgIcon]="item.icon"></mat-icon>
            <span mat-line>{{ item.name | bmsTranslate }}</span>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
  </div>
</section>
