import {Component} from '@angular/core';
import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {Color} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  templateUrl: './grid-colors-of-creel-position.component.html',
  styleUrls: ['./grid-colors-of-creel-position.component.scss']
})
export class GridColorsOfCreelPositionComponent implements ICellRendererAngularComp {
  public colors: Color[];
  public showRgbValues: boolean;

  public agInit(params: any): void {
    const creelPositionForColors: CreelPosition | OverviewListCreelPosition = params.data.creelPositionForColors;

    this.showRgbValues = params.data.creelPositionForColors.showRgbValues ?? true;
    this.colors = creelPositionForColors instanceof CreelPosition ? (creelPositionForColors.getSortedItems() as Color[]) : (creelPositionForColors.items as Color[]);
  }

  public refresh(params: any): boolean {
    return false;
  }
}
