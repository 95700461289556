import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {TabsMenuItem} from './tabs-menu-item';

@Component({
  selector: 'vdw-tabs-menu',
  templateUrl: './tabs-menu.component.html'
})
export class TabsMenuComponent {
  @Input() public menuItems: TabsMenuItem[];
  @Input() public selectedMenuItem: TabsMenuItem;
  @Output() public selectedMenuItemChange: EventEmitter<TabsMenuItem> = new EventEmitter<TabsMenuItem>();

  public isMenuItemSelected(menuItem: TabsMenuItem): boolean {
    return menuItem.value === this.selectedMenuItem.value;
  }

  public selectMenuItem(menuItem: TabsMenuItem): void {
    if (!menuItem.disabled) {
      this.selectedMenuItemChange.emit(menuItem);
    }
  }

  public canShowTabError(menuItem: TabsMenuItem): boolean {
    return !!menuItem.canShowTabError || (!!menuItem.form && menuItem.form.invalid && !menuItem.form.disabled && this.isEveryFormControlInFormGroupTouched(menuItem.form));
  }

  private isEveryFormControlInFormGroupTouched(form: FormGroup | FormArray): boolean {
    return Object.keys(form.controls).every((controlName: string) => {
      return form.get(controlName) instanceof FormGroup || form.get(controlName) instanceof FormArray
        ? this.isEveryFormControlInFormGroupTouched(form.get(controlName) as FormGroup)
        : form.get(controlName).touched;
    });
  }
}
