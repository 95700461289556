import {NgModule} from '@angular/core';
import {SplitButtonComponent} from './split-button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {TranslationModule} from '../../translation/translation.module';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [SplitButtonComponent],
  imports: [CommonModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, TranslationModule],
  exports: [SplitButtonComponent]
})
export class SplitButtonModule {}
