import {Location} from '@angular/common';
import {ChangeDetectorRef, Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MobileNavigationBarExternalLinkItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-external-link-item';
import {DialogBuilderFactoryService, WINDOW} from '@vdw/angular-component-library';
import {Observable, Subject, takeUntil} from 'rxjs';
import {NavigationSearchService} from '../navigation-search.service';
import {MobileNavigationBarRouteLinkItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-route-link-item';
import {MobileNavigationBarItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-item';
import {MobileNavigationBarActionItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-action-item';

@Injectable({providedIn: 'root'})
export class MobileNavigationComponentService {
  public constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly router: Router,
    private readonly location: Location,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly navigationSearchService: NavigationSearchService
  ) {}

  public chooseMenuItem(event: MouseEvent, navigationBarItem: MobileNavigationBarItem, changeDetectorRef: ChangeDetectorRef, unSubscribeOnViewDestroy: Subject<boolean>): any {
    if (navigationBarItem instanceof MobileNavigationBarRouteLinkItem) {
      this.router.navigateByUrl(navigationBarItem.path);
      this.navigationSearchService.notifySearchClosed();
    } else if (navigationBarItem instanceof MobileNavigationBarExternalLinkItem) {
      this.window.open(navigationBarItem.path);
      this.navigationSearchService.notifySearchClosed();
    } else if (navigationBarItem instanceof MobileNavigationBarActionItem) {
      if (navigationBarItem.id === 'search') {
        this.navigationSearchService.notifySearchOpened();
        navigationBarItem.active = true;

        this.navigationSearchService.navigationCloseSearchEmitter.pipe(takeUntil(unSubscribeOnViewDestroy)).subscribe(() => (navigationBarItem.active = false));
        return;
      }

      const changed = navigationBarItem.executeCommand({dialog: this.dialogBuilderFactoryService, event, changeDetectorRef});

      navigationBarItem.active = true;
      if (changed instanceof Observable) {
        changed.pipe(takeUntil(unSubscribeOnViewDestroy)).subscribe(() => (navigationBarItem.active = false));
      }
    }
  }

  public isActive(item: MobileNavigationBarItem): boolean {
    if (this.location.isCurrentPathEqualTo((item as MobileNavigationBarRouteLinkItem).path)) {
      return true;
    }

    if (item instanceof MobileNavigationBarActionItem) {
      return item.active;
    }

    const currentBasePath = this.location.path().split('/')[1];
    const endOfNavigationItemPath = (item as MobileNavigationBarRouteLinkItem).path?.split('/').slice(-1)[0];
    return currentBasePath && endOfNavigationItemPath ? currentBasePath === endOfNavigationItemPath : false;
  }
}
