import {Injectable} from '@angular/core';
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {ToastParams} from '../toast-params.interface';
import {TranslateService} from './../../../translation/translate.service';

@Injectable({providedIn: 'root'})
export class ToastService {
  public constructor(
    private readonly toastService: ToastrService,
    private readonly translateService: TranslateService
  ) {}

  public showInfo(params: ToastParams, buttonText?: string, action?: () => void): ActiveToast<any> {
    return this.show(params, 'info', 'info', buttonText, action);
  }

  public showError(params: ToastParams, buttonText?: string, action?: () => void): ActiveToast<any> {
    return this.show(params, 'error-full-52px', 'error', buttonText, action);
  }

  public showWarning(params: ToastParams, buttonText?: string, action?: () => void): ActiveToast<any> {
    return this.show(params, 'warning-full-52px', 'warning', buttonText, action);
  }

  public showNewUpdateApplied(params: ToastParams, buttonText?: string, action?: () => void): ActiveToast<any> {
    return this.show(params, 'update-blue', 'info', buttonText, action);
  }

  private show(params: ToastParams, titleIcon: string, statusBarClass: string, buttonText?: string, action?: () => void): ActiveToast<any> {
    const toast = this.getActive(params);

    toast.toastRef.componentInstance.titleIcon = titleIcon;
    toast.toastRef.componentInstance.statusBarClass = statusBarClass;
    toast.toastRef.componentInstance.action = action;
    toast.toastRef.componentInstance.buttonText = buttonText;

    return toast;
  }

  private getActive(params: ToastParams): ActiveToast<any> {
    const {tapToDismiss, timeOut, message, title, extendedTimeOut, positionClass} = params;

    this.toastService.toastrConfig.tapToDismiss = tapToDismiss;
    this.toastService.toastrConfig.timeOut = timeOut;
    this.toastService.toastrConfig.positionClass = positionClass;
    this.toastService.toastrConfig.preventDuplicates = true;
    this.toastService.toastrConfig.resetTimeoutOnDuplicate = true;

    if (extendedTimeOut) {
      this.toastService.toastrConfig.extendedTimeOut = extendedTimeOut;
    }

    return this.toastService.show(this.translateService.instant(message), title);
  }
}
