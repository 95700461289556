<div class="bms-theme editor-header-container">
  <vdw-tabs-menu [menuItems]="menuItems" [selectedMenuItem]="selectedMenuItem" (selectedMenuItemChange)="selectedMenuItemChange.emit($event)"></vdw-tabs-menu>

  <div class="action-buttons-container">
    <button mat-icon-button #extraActionsTrigger="matMenuTrigger" [matMenuTriggerFor]="extraActions" [class.open]="extraActionsTrigger.menuOpen" *ngIf="canShowExtraActions()">
      <mat-icon svgIcon="solid-more-vert"></mat-icon>
    </button>
    <mat-menu #extraActions="matMenu" class="bms-theme">
      <button *ngIf="canShowDuplicate" mat-menu-item (click)="duplicateEvent.emit()">
        <mat-icon svgIcon="solid-content-copy"></mat-icon>{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.DUPLICATE' | bmsTranslate }}
      </button>
      <button *ngIf="canShowDelete" mat-menu-item (click)="deleteEvent.emit()"><mat-icon svgIcon="solid-delete"></mat-icon>{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.DELETE' | bmsTranslate }}</button>
      <button *ngIf="canShowSettings" mat-menu-item (click)="settingsEvent.emit()">
        <mat-icon svgIcon="solid-settings"></mat-icon>{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.SETTINGS' | bmsTranslate }}
      </button>
      <button *ngFor="let extraAction of customExtraActions" mat-menu-item [disabled]="extraAction.disabled" (click)="extraAction.onClick($event)">
        <mat-icon [svgIcon]="extraAction.icon"></mat-icon>{{ extraAction.label }}
      </button>
    </mat-menu>

    <button mat-button *ngIf="canShowCancel" (click)="cancelEvent.emit()">{{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.CANCEL' | bmsTranslate }}</button>

    <vdw-split-button
      *ngIf="canShowSplitButton(); else singleAction"
      [label]="getSaveButtonTranslationKey() | bmsTranslate: {action: actionText | bmsTranslate}"
      (buttonClicked)="save(defaultSaveType)"
    >
      <button *ngIf="canShowSaveMenuButton()" mat-menu-item (click)="save(SAVE)">{{ actionText | bmsTranslate }}</button>
      <button *ngIf="canShowSaveAndCloseMenuButton()" mat-menu-item (click)="save(SAVE_AND_CLOSE)">
        {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CLOSE' | bmsTranslate: {action: actionText | bmsTranslate} }}
      </button>
      <button *ngIf="canShowSaveAndCreateNewMenuButton()" mat-menu-item (click)="save(SAVE_AND_CREATE_NEW)">
        {{ 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CREATE_NEW' | bmsTranslate: {action: actionText | bmsTranslate} }}
      </button>
    </vdw-split-button>
    <ng-template #singleAction>
      <button *ngIf="canShowSave" mat-flat-button (click)="save(defaultSaveType)">{{ getSaveButtonTranslationKey() | bmsTranslate: {action: actionText | bmsTranslate} }}</button>
    </ng-template>
  </div>
</div>
