import {InjectionToken} from '@angular/core';
import {Subscription} from '@domain/profile/subscription';
import {AuthenticationToken} from '@infrastructure/http/authentication/authentication-token';
import {PotentialUser} from '@presentation/pages/register/potential-user';
import {Observable} from 'rxjs';

export const AUTHENTICATION = new InjectionToken<Authentication>('Authentication');

export interface Authentication {
  onAuthentication(): Observable<AuthenticationToken>;

  login(email: string): void;

  logout(): void;

  isAuthenticated(): boolean;

  currentAuthenticatedChanges(): Observable<boolean>;

  getToken(): Observable<string>;

  getCurrentSubscription(): Subscription;

  validateCurrentSubscription(): void;

  setCurrentSubscription(subscription: Subscription): void;

  currentSubscriptionChanges(): Observable<Subscription>;

  getCurrentEmail(): string;

  getRedirectAfterAuthentication(): string;

  setRedirectAfterAuthentication(url: string): void;

  getCurrentUserId(): string;

  register(potentialUser: PotentialUser): Observable<void>;

  resetLocalStorageToDefaults(): void;

  clearSubscription(): void;

  isAuthenticationInProgress(): Observable<boolean>;

  handleInvalidSubscription(): void;
}
