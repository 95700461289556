import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'vdw-split-button',
  templateUrl: './split-button.component.html'
})
export class SplitButtonComponent {
  @Input() public label: string;
  @Input() public stroked = false;
  @Input() public disabled = false;
  @Input() public compact = false;
  @Output() public buttonClicked = new EventEmitter<void>();
}
