import {AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {MatTooltip, TooltipPosition} from '@angular/material/tooltip';
import {isUndefined} from 'lodash-es';

@Component({
  selector: 'vdw-ellipsis-label, [vdw-ellipsis-label]',
  templateUrl: './ellipsis-label.component.html',
  styleUrls: ['./ellipsis-label.component.scss']
})
export class EllipsisLabelComponent implements AfterViewInit, AfterContentInit, OnDestroy {
  @Input() public text: string;
  @Input() public tooltipClasses: string[] = [];
  @Input() public matTooltipPosition: TooltipPosition = 'below';
  @Input() public tooltipDisabled = false;
  @Input() public hasContent = false;
  @ViewChild('label') public label: ElementRef<HTMLElement>;
  @ViewChild('ellipsisTooltip') public ellipsisTooltip: MatTooltip;
  public showTooltip = false;

  private resizeObserver: ResizeObserver;
  private mutationObserver: MutationObserver;

  public get tooltipClass(): string {
    return this.tooltipClasses.join(' ');
  }

  public ngAfterViewInit(): void {
    this.observeResize();
    this.observeMutation();
  }

  public ngAfterContentInit(): void {
    this.showTooltip = this.canShowTooltip();
  }

  public ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.label.nativeElement);
    this.mutationObserver.disconnect();
  }

  private observeResize(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.showTooltip = this.canShowTooltip();
    });
    this.resizeObserver.observe(this.label.nativeElement, {box: 'border-box'});
  }

  private observeMutation(): void {
    this.mutationObserver = new MutationObserver(() => {
      this.showTooltip = this.canShowTooltip();
    });
    this.mutationObserver.observe(this.label.nativeElement, {characterData: true, attributes: true, childList: true, subtree: true});
  }

  private canShowTooltip(): boolean {
    if (this.tooltipDisabled || isUndefined(this.label)) {
      return false;
    }

    const labelElement = this.label.nativeElement;

    return labelElement.scrollWidth > labelElement.clientWidth;
  }
}
