<mat-form-field [formGroup]="form" floatLabel="always" class="full-width" disabled [class.bms-theme]="canShowBMSTheme">
  <mat-label>
    <span>{{ inputLabel }}</span>
    <span class="optional" *ngIf="optional">({{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.OPTIONAL' | bmsTranslate | lowercase }})</span>
  </mat-label>
  <input
    matInput
    [formControlName]="formControlNameValue"
    [placeholder]="placeholder"
    [min]="minValue"
    [max]="maxValue"
    [matDatepicker]="datePicker"
    [value]="value"
    (dateChange)="onDateChange($event)"
  />
  <button *ngIf="canShowClearInputButton()" class="clear-input-button" mat-icon-button type="button" (click)="form.controls[this.formControlNameValue].reset()">
    <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
  </button>
  <mat-hint *ngIf="showHint">({{ hint }})</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="datePicker">
    <mat-icon matDatepickerToggleIcon [svgIcon]="canShowBMSTheme ? 'solid-calendar-today' : 'calendar'" class="mat-datepicker-toggle-icon"></mat-icon>
  </mat-datepicker-toggle>

  <mat-error *ngIf="canShowRequiredError()">
    <span *ngIf="!canShowBMSTheme">{{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.REQUIRED' | bmsTranslate }}</span>
    <vdw-invalid-form-message *ngIf="canShowBMSTheme" [message]="'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.REQUIRED' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
  </mat-error>
  <mat-error *vdwCanShowErrorForFormControl="formControlNameValue; error: 'matDatepickerParse'">
    <span *ngIf="!canShowBMSTheme">{{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.INVALID_FORMAT' | bmsTranslate }}</span>
    <vdw-invalid-form-message *ngIf="canShowBMSTheme" [message]="'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.INVALID_FORMAT' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
  </mat-error>
  <mat-error *vdwCanShowErrorForFormControl="formControlNameValue; error: 'matDatepickerMin'">
    <span *ngIf="!canShowBMSTheme">{{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.MIN_VALUE' | bmsTranslate: {min: minValue} }}</span>
    <vdw-invalid-form-message
      *ngIf="canShowBMSTheme"
      [message]="'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.MIN_VALUE' | bmsTranslate: {min: minValue | momentDate: 'MM/DD/YYYY'}"
      [compact]="true"
    ></vdw-invalid-form-message>
  </mat-error>
  <mat-error *vdwCanShowErrorForFormControl="formControlNameValue; error: 'matDatepickerMax'">
    <span *ngIf="!canShowBMSTheme">{{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.MAX_VALUE' | bmsTranslate: {max: maxValue} }}</span>
    <vdw-invalid-form-message
      *ngIf="canShowBMSTheme"
      [message]="'ANGULAR_COMPONENT_LIBRARY.DATEPICKER_FORM.MAX_VALUE' | bmsTranslate: {max: maxValue | momentDate: 'MM/DD/YYYY'}"
      [compact]="true"
    ></vdw-invalid-form-message>
  </mat-error>

  <mat-datepicker (monthSelected)="onMonthSelected()" #datePicker [calendarHeaderComponent]="datepickerHeader">
    <mat-datepicker-actions class="date-actions">
      <button mat-button (click)="clear()">{{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER.CLEAR' | bmsTranslate }}</button>
      <button class="visibility-hidden" mat-raised-button matDateRangePickerApply #applyButton>Apply</button>
      <button mat-button (click)="today()">{{ 'ANGULAR_COMPONENT_LIBRARY.DATEPICKER.TODAY' | bmsTranslate }}</button>
    </mat-datepicker-actions>
  </mat-datepicker>
</mat-form-field>
