import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BaseComponent, DialogData, SelectionListItem, ToastParams, ToastService, TranslateService} from '@vdw/angular-component-library';
import {Observable, Subject, take, takeUntil} from 'rxjs';
import {PushNotificationsService} from '../push-notifications.service';

@Component({
  templateUrl: './notification-list-settings.component.html',
  styleUrls: ['./notification-list-settings.component.scss']
})
export class NotificationListSettingsComponent extends BaseComponent implements OnInit, DialogData<SelectionListItem[]> {
  private readonly filterChanged = new Subject<SelectionListItem[]>();
  public isSubscriptionProcessing = false;
  public toggleValue = false;

  private readonly toastParams: ToastParams = {
    tapToDismiss: true,
    timeOut: 4000,
    title: this.translate.instant('NOTIFICATIONS.SUBSCRIPTION_DISABLED_TOAST_TITLE'),
    message: this.translate.instant('NOTIFICATIONS.SUBSCRIPTION_DISABLED_TOAST_MESSAGE'),
    positionClass: 'toast-top-right'
  };

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService,
    private readonly pushNotificationsService: PushNotificationsService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.pushNotificationsService
      .subscriptionEnabledInBrowser()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), take(1))
      .subscribe((_: PushSubscription) => {
        this.toggleValue = Notification.permission === 'denied' ? false : this.pushNotificationsService.isSubscribed;
      });
  }

  public getValue(): Observable<SelectionListItem[]> {
    return this.filterChanged.asObservable();
  }

  public onFilterOptionChanged(value: SelectionListItem[]): void {
    this.filterChanged.next(value);
  }

  public isBrowserSupported(): boolean {
    return this.pushNotificationsService.isBrowserSupported();
  }

  public toggleSubscription(): void {
    if (Notification.permission === 'denied') {
      this.toggleValue = false;
      this.toastService.showError(this.toastParams);
      return;
    } else if (Notification.permission === 'default') {
      this.toggleValue = false;
    }

    this.isSubscriptionProcessing = true;

    if (this.pushNotificationsService.isSubscribed) {
      this.pushNotificationsService
        .unsubscribeToPushNotifications()
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(() => (this.isSubscriptionProcessing = false));
    } else {
      this.pushNotificationsService
        .subscribeToPushNotifications()
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: () => {
            this.toggleValue = true;
            this.isSubscriptionProcessing = false;
          },
          error: () => (this.isSubscriptionProcessing = false)
        });
    }
  }
}
