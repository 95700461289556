<nav mat-tab-nav-bar *ngIf="menuItems.length" mat-stretch-tabs="false" mat-align-tabs="start" [tabPanel]="tabPanel" class="ml-24">
  <a
    mat-tab-link
    *ngFor="let menuItem of menuItems"
    class="tab-skeleton-wrapper"
    [active]="isMenuItemSelected(menuItem)"
    (click)="selectMenuItem(menuItem)"
    [class.tab-error]="canShowTabError(menuItem)"
    [disabled]="menuItem.disabled"
  >
    {{ menuItem.translationKey | bmsTranslate: menuItem.translationParameters }}
  </a>
</nav>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
