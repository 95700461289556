import {AgGridModule} from 'ag-grid-angular';
import {CrudOverviewDataPageComponent} from './crud-overview-data-page/crud-overview-data-page.component';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogModule} from '../../dialogs/dialog.module';
import {ToggleButtonModule} from '../toggle-button/toggle-button.module';
import {GridSelectionsOptionsModule} from '../grid-selection-options/grid-selection-options.module';
import {TranslationModule} from '../../translation/translation.module';

@NgModule({
  imports: [CommonModule, AgGridModule, MatIconModule, MatButtonModule, MatTooltipModule, DialogModule, ToggleButtonModule, GridSelectionsOptionsModule, TranslationModule],
  declarations: [CrudOverviewDataPageComponent],
  exports: [CrudOverviewDataPageComponent]
})
export class CrudOverviewDataModule {}
