<div class="header-container bms-theme">
  <div class="sidenav-container mr-8">
    <button #sidebarButton mat-icon-button class="button" (click)="sidebarButtonClicked()" (mouseenter)="sidebarEnter()">
      <mat-icon [svgIcon]="applicationLayoutService.sidebarIcon"></mat-icon>
    </button>
  </div>
  <div class="path-container">
    <vdw-breadcrumb [(items)]="breadcrumbItems" (itemsChanged)="onBreadcrumbItemsChanged($event)" [disablePredicate]="disablePredicate"></vdw-breadcrumb>
  </div>
  <div *ngIf="canShowMaintenanceNotification()" class="application-maintenance-container">
    <a class="url" [href]="maintenanceNotification.url" target="_blank">
      <vdw-ellipsis-label [text]="maintenanceNotification.message" matTooltipPosition="above"></vdw-ellipsis-label>
    </a>
  </div>
  <div class="icon-container">
    <vdw-dialog-button
      class="button"
      #notificationsButton
      [componentType]="notificationsComponent"
      [dialogData]="{}"
      [showArrow]="true"
      [verticaloffset]="1"
      [horizontalOffset]="59"
      [dialogHeight]="'calc(100vh - 72px)'"
      [iconName]="'solid-notifications'"
    >
      <button
        mat-icon-button
        [class.open]="notificationsButton.open"
        (click)="notificationsButton.toggleDialog()"
        [matTooltip]="'ONBOARDING.NAVIGATION.NOTIFICATIONS.TITLE' | bmsTranslate"
        [matTooltipClass]="'bms-theme'"
      >
        <mat-icon svgIcon="solid-notifications" [matBadge]="unreadNotifications" matBadgeSize="small" [matBadgeHidden]="hasNoUnreadNotifications()"></mat-icon>
      </button>
    </vdw-dialog-button>
    <button mat-icon-button *ngIf="hasSettingsPermission" class="button" (click)="settingsClicked()" [matTooltip]="'SETTINGS.MENU_NAME' | bmsTranslate" [matTooltipClass]="'bms-theme'">
      <mat-icon svgIcon="solid-settings"></mat-icon>
    </button>
    <button mat-icon-button class="button" (click)="helpClicked()" [matTooltip]="'ONBOARDING.NAVIGATION.HELP.TITLE' | bmsTranslate" [matTooltipClass]="'bms-theme'">
      <mat-icon svgIcon="solid-help"></mat-icon>
    </button>
  </div>
</div>
