<div class="bms-theme split-button">
  <ng-container *ngIf="!stroked; else strokedButton">
    <button mat-flat-button [class.compact]="compact" [disabled]="disabled" (click)="buttonClicked.emit()">{{ label | bmsTranslate }}</button>
    <mat-divider vertical></mat-divider>
    <button mat-flat-button #otherOptionsTrigger="matMenuTrigger" [matMenuTriggerFor]="otherOptionsMenu" [class.compact]="compact" [class.open]="otherOptionsTrigger.menuOpen" [disabled]="disabled">
      <mat-icon [svgIcon]="otherOptionsTrigger.menuOpen ? 'solid-keyboard-arrow-up' : 'solid-keyboard-arrow-down'"></mat-icon>
    </button>
  </ng-container>
</div>

<ng-template #strokedButton>
  <button mat-stroked-button [class.compact]="compact" [disabled]="disabled" (click)="buttonClicked.emit()">{{ label | bmsTranslate }}</button>
  <mat-divider vertical></mat-divider>
  <button mat-stroked-button #otherOptionsTrigger="matMenuTrigger" [matMenuTriggerFor]="otherOptionsMenu" [class.compact]="compact" [class.open]="otherOptionsTrigger.menuOpen" [disabled]="disabled">
    <mat-icon [svgIcon]="otherOptionsTrigger.menuOpen ? 'solid-keyboard-arrow-up' : 'solid-keyboard-arrow-down'"></mat-icon>
  </button>
</ng-template>

<mat-menu #otherOptionsMenu="matMenu" class="bms-theme"><ng-content></ng-content></mat-menu>
