<div class="account-container">
  <div class="company-container gap-4 flex-column" *ngFor="let company of data.companies">
    <div class="company-name">{{ company.name }}</div>
    <vdw-account-switch
      class="account-switch"
      [class.active]="isActiveSubscription(subscription.id)"
      *ngFor="let subscription of companySubscriptions.get(company.id)"
      (click)="subscriptionClicked(subscription.id, company.id)"
      [name]="subscription.name"
      [id]="subscription.id"
    ></vdw-account-switch>
  </div>
</div>

<div class="button-container flex-column">
  <button mat-button (click)="profileClicked()">{{ 'PROFILE.PROFILE' | bmsTranslate }}</button>
  <button mat-button (click)="manageSubscriptionsClicked()">{{ 'AUTHENTICATION.SUBSCRIPTIONS.MANAGE_SUBSCRIPTIONS' | bmsTranslate }}</button>
  <button mat-button (click)="logoutClicked()">{{ 'AUTHENTICATION.LOGOUT' | bmsTranslate }}</button>
</div>
