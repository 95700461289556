import {Component, Inject} from '@angular/core';
import {CrudOverviewExtraActionConfig} from '../../crud-overview-data/interfaces/crud-overview-extra-action-config.interface';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../../dialog-button/dialog-data.interface';
import {Observable, of} from 'rxjs';

@Component({
  templateUrl: './grid-selection-extra-actions-dialog.component.html',
  styleUrls: ['./grid-selection-extra-actions-dialog.component.scss']
})
export class GridSelectionExtraActionsDialogComponent implements DialogData<any> {
  public constructor(@Inject(MAT_DIALOG_DATA) public readonly config: {extraActions: CrudOverviewExtraActionConfig[]}) {}

  public getValue(): Observable<any> {
    return of();
  }
}
