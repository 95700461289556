import {NgModule} from '@angular/core';
import {ColorPickerFormInputComponent} from './color-picker-form-input.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [ColorPickerFormInputComponent],
  imports: [ReactiveFormsModule, MatIconModule],
  exports: [ColorPickerFormInputComponent]
})
export class ColorPickerFormInputModule {}
