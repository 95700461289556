import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MobileNavigationBarItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-item';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {BaseComponent, ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs/operators';
import {MobileNavigationComponentService} from './mobile-navigation-component.service';
import {SideNavigationStateService} from '../side-navigation-state.service';
import {MobileNavigationService} from '@application/services/navigation/mobile-navigation.service';

@Component({
  selector: 'app-mobile-navigation-sidebar',
  templateUrl: './mobile-navigation-sidebar.component.html',
  styleUrls: ['./mobile-navigation-sidebar.component.scss']
})
export class MobileNavigationSidebarComponent extends BaseComponent implements OnInit {
  public navigationBarItems: MobileNavigationBarItem[];

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode,
    private readonly sideNavigationStateService: SideNavigationStateService,
    private readonly navigationComponentService: MobileNavigationComponentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly mobileNavigationService: MobileNavigationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.responsivenessViewMode.observeDeviceChanges().subscribe(() => (this.navigationBarItems = this.mobileNavigationService.getNavigationBarItems(this.authentication.getCurrentSubscription())));

    this.authentication
      .currentSubscriptionChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((subscription: Subscription) => (this.navigationBarItems = this.mobileNavigationService.getNavigationBarItems(subscription)));
  }

  public isActive(item: MobileNavigationBarItem): boolean {
    return this.navigationComponentService.isActive(item);
  }

  public chooseMenuItem(event: MouseEvent, navigationBarItem: MobileNavigationBarItem): void {
    this.navigationComponentService.chooseMenuItem(event, navigationBarItem, this.changeDetectorRef, this.unSubscribeOnViewDestroy);
  }

  public closeNavigation(): void {
    this.sideNavigationStateService.changeNavigationStateToClosed();
  }
}
