import {ComponentsRoute} from '@application/helper/routing/components-route';
import {RouteBranch} from '@application/helper/routing/route-branch';
import {RouteLeaf} from '@application/helper/routing/route-leaf';
import {RouteNode} from '@application/helper/routing/route-node';
import {TexBiRoute} from '@application/helper/routing/tex-bi-route';
import {TexBotRoute} from '@application/helper/routing/tex-bot-route';
import {TexEditRoute} from '@application/helper/routing/tex-edit-route';
import {TexFabRoute} from '@application/helper/routing/tex-fab-route';
import {TexStateRoute} from '@application/helper/routing/tex-state-route';
import {TexStyleRoute} from '@application/helper/routing/tex-style-route';
import {ProfileRoute} from './profile-route';
import {SettingsRoute} from './settings-route';
import {UtilitiesRoute} from './utilities-route';

export class RootRoute extends RouteBranch {
  private _login: RouteLeaf;
  private _register: RouteLeaf;
  private _texEdit: TexEditRoute;
  private _texState: TexStateRoute;
  private _texBi: TexBiRoute;
  private _profile: ProfileRoute;
  private _help: RouteLeaf;
  private _unauthorized: RouteLeaf;
  private _notFound: RouteLeaf;
  private _credits: RouteLeaf;
  private _texStyle: TexStyleRoute;
  private _texFab: TexFabRoute;
  private _texBot: TexBotRoute;
  private _utilities: UtilitiesRoute;
  private _components: ComponentsRoute;
  private _loadingFile: RouteLeaf;
  private _logger: RouteLeaf;
  private _processes: RouteLeaf;
  private _settings: SettingsRoute;
  private _notifications: RouteLeaf;

  public get login(): RouteLeaf {
    return this._login;
  }

  public set login(value: RouteLeaf) {
    this.replace(this._login, value);
    this._login = value;
  }

  public get register(): RouteLeaf {
    return this._register;
  }

  public set register(value: RouteLeaf) {
    this.replace(this._register, value);
    this._register = value;
  }

  public get texEdit(): TexEditRoute {
    return this._texEdit;
  }

  public set texEdit(value: TexEditRoute) {
    this.replace(this._texEdit, value);
    this._texEdit = value;
  }

  public get texState(): TexStateRoute {
    return this._texState;
  }

  public set texState(value: TexStateRoute) {
    this.replace(this._texState, value);
    this._texState = value;
  }

  public get texBi(): TexBiRoute {
    return this._texBi;
  }

  public set texBi(value: TexBiRoute) {
    this.replace(this._texBi, value);
    this._texBi = value;
  }

  public get profile(): ProfileRoute {
    return this._profile;
  }

  public set profile(value: ProfileRoute) {
    this.replace(this._profile, value);
    this._profile = value;
  }

  public get help(): RouteLeaf {
    return this._help;
  }

  public set help(value: RouteLeaf) {
    this.replace(this._help, value);
    this._help = value;
  }

  public get unauthorized(): RouteLeaf {
    return this._unauthorized;
  }

  public set unauthorized(value: RouteLeaf) {
    this.replace(this._unauthorized, value);
    this._unauthorized = value;
  }

  public get notFound(): RouteLeaf {
    return this._notFound;
  }

  public set notFound(value: RouteLeaf) {
    this.replace(this._notFound, value);
    this._notFound = value;
  }

  public get credits(): RouteLeaf {
    return this._credits;
  }

  public set credits(value: RouteLeaf) {
    this.replace(this._credits, value);
    this._credits = value;
  }

  public get texStyle(): TexStyleRoute {
    return this._texStyle;
  }

  public set texStyle(value: TexStyleRoute) {
    this.replace(this._texStyle, value);
    this._texStyle = value;
  }

  public get texFab(): TexFabRoute {
    return this._texFab;
  }

  public set texFab(value: TexFabRoute) {
    this.replace(this._texFab, value);
    this._texFab = value;
  }

  public get texBot(): TexBotRoute {
    return this._texBot;
  }

  public set texBot(value: TexBotRoute) {
    this.replace(this._texBot, value);
    this._texBot = value;
  }

  public get utilities(): UtilitiesRoute {
    return this._utilities;
  }

  public set utilities(value: UtilitiesRoute) {
    this.replace(this._utilities, value);
    this._utilities = value;
  }

  public get components(): ComponentsRoute {
    return this._components;
  }

  public set components(value: ComponentsRoute) {
    this.replace(this._components, value);
    this._components = value;
  }

  public get loadingFile(): RouteLeaf {
    return this._loadingFile;
  }

  public set loadingFile(value: RouteLeaf) {
    this.replace(this._loadingFile, value);
    this._loadingFile = value;
  }

  public get logger(): RouteLeaf {
    return this._logger;
  }

  public set logger(value: RouteLeaf) {
    this.replace(this._logger, value);
    this._logger = value;
  }

  public get processes(): RouteLeaf {
    return this._processes;
  }

  public set processes(value: RouteLeaf) {
    this.replace(this._processes, value);
    this._processes = value;
  }

  public get settings(): SettingsRoute {
    return this._settings;
  }

  public set settings(value: SettingsRoute) {
    this.replace(this._settings, value);
    this._settings = value;
  }

  public get notifications(): RouteLeaf {
    return this._notifications;
  }

  public set notifications(value: RouteLeaf) {
    this.replace(this._notifications, value);
    this._notifications = value;
  }

  public isKnownPath(pathSegments: string[]): boolean {
    return this.getRoutes()?.some((routeNode: RouteNode) => routeNode.isKnownPath(pathSegments));
  }
}
