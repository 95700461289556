import {Component} from '@angular/core';
import {CreelPosition} from '@domain/textile-data/creel/creel-position';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  templateUrl: './grid-yarn-types-of-creel-position.component.html',
  styleUrls: ['./grid-yarn-types-of-creel-position.component.scss']
})
export class GridYarnTypesOfCreelPositionComponent implements ICellRendererAngularComp {
  public yarnTypes: OverviewListYarnType[];
  public position: number;
  public isCreelPositionDifferentFromTheoreticalCreel = false;

  public agInit(params: any): void {
    const creelPositionForYarnTypes: CreelPosition | OverviewListCreelPosition = params.data.creelPositionForYarnTypes;
    this.yarnTypes =
      creelPositionForYarnTypes instanceof CreelPosition ? (creelPositionForYarnTypes.getSortedItems() as OverviewListYarnType[]) : (creelPositionForYarnTypes.items as OverviewListYarnType[]);

    if (params.isCreelPositionDifferentFromTheoreticalCreel) {
      this.isCreelPositionDifferentFromTheoreticalCreel = params.isCreelPositionDifferentFromTheoreticalCreel;
      this.position = (creelPositionForYarnTypes as OverviewListCreelPosition).position;
    }
  }

  public refresh(params: any): boolean {
    return false;
  }
}
