import {Component, Input} from '@angular/core';
import {L10nIntlService} from 'angular-l10n';
import {convertUnit} from '../../common/converters/convert-unit';
import {Unit} from '../../common/unit.enum';
import {LocaleUtils} from '../../common/utils/locale-utils';
import {TranslateService} from '../../translation/translate.service';
import {DistanceSpecifierOrientation} from './distance-specifier-orientation.enum';

@Component({
  selector: 'vdw-distance-specifier',
  templateUrl: './distance-specifier.component.html',
  styleUrls: ['./distance-specifier.component.scss']
})
export class DistanceSpecifierComponent {
  @Input() public containerWidthInPx: number = 300;
  @Input() public commercialDistanceInMM: number = 0;
  @Input() public technicalDistance: number = 0;
  @Input() public orientation: DistanceSpecifierOrientation = DistanceSpecifierOrientation.HORIZONTAL;

  private readonly MIN_WIDTH_TO_SHOW_LABEL = 150;
  private readonly MIN_WIDTH_TO_SHOW = 15;

  public constructor(
    private readonly l10nIntlService: L10nIntlService,
    private readonly translate: TranslateService
  ) {}

  public getDistanceSpecifierLabel(): string {
    const isHorizontalOrientation = this.orientation === DistanceSpecifierOrientation.HORIZONTAL;

    const picksTranslation = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.DISTANCE_SPECIFIER.UNIT.PICKS');
    const dentsTranslation = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.DISTANCE_SPECIFIER.UNIT.DENTS');

    const distanceInTechnicalUnit = `${this.technicalDistance} ${isHorizontalOrientation ? dentsTranslation : picksTranslation}`;
    const distanceInCommercialUnit = this.convertValueToCommercialUnit(this.commercialDistanceInMM, Unit.MILLIMETER, Unit.CENTIMETER);

    return `${distanceInTechnicalUnit} / ${distanceInCommercialUnit}`;
  }

  public hasVerticalOrientation(): boolean {
    return this.orientation === DistanceSpecifierOrientation.VERTICAL;
  }

  public canShow(): boolean {
    return this.containerWidthInPx >= this.MIN_WIDTH_TO_SHOW;
  }

  public canShowLabel(): boolean {
    return this.containerWidthInPx >= this.MIN_WIDTH_TO_SHOW_LABEL;
  }

  private convertValueToCommercialUnit(value: number, fromUnit: Unit, toUnit: Unit): string {
    const convertedValue = convertUnit({
      from: {
        value: value,
        unit: fromUnit
      },
      to: toUnit
    });

    const formattedNumber = LocaleUtils.formatNumber(convertedValue, this.l10nIntlService, 2);
    const unit = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.DISTANCE_SPECIFIER.UNIT.CENTIMETER');

    return `${formattedNumber} ${unit}`;
  }
}
