import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {TranslationModule} from '../../translation/translation.module';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {DateRangePickerComponent} from './date-range-picker/date-range-picker.component';
import {DatepickerHeaderComponent} from './datepicker-header/datepicker-header.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatButtonModule, TranslationModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, ReactiveFormsModule],
  exports: [DatepickerHeaderComponent, DatePickerComponent, DateRangePickerComponent],
  declarations: [DatepickerHeaderComponent, DatePickerComponent, DateRangePickerComponent],
  providers: []
})
export class DatePickerModule {}
